export const ATG_CONNECTION_SUCCESS_STATUS = 'success';
export const ATG_CONNECTION_FAIL_STATUS = 'fail';
export const ATG_CONNECTION_UNKNOWN_STATUS = 'unknown';

export const CONNECTION_MODAL_ADDRESS_STEP = 1;
export const CONNECTION_MODAL_DIAGNOSE_STEP = 2;

export const DIAGNOSE_MODAL_CONNECTION_STEP = 1;
export const DIAGNOSE_MODAL_COMMAND_STEP = 2;
export const DIAGNOSE_MODAL_RESULT_STEP = 3;
