export const tlsSites = [
  {
    atg_site: 'Test Site - TLS 300 - DNR',
    site_id: '551c93b013d5342d2d00f752',
    client_name: 'Test',
    address: '1203 Governors Square Blvd, Suite 101',
    city: 'Tallahassee',
    state: 'FL',
    client_id: '551c938013d5342d2d00f751',
    atg_type: 'TLS 300',
    status: 'success',
    last_connection: '05/13/2023 7:00 AM (EST)',
    host: '96.72.31.105',
    port: 10001,
    tags: ['TAG1', 'TAG2', 'TAGTAGTAG_TAG_TAG']
  }
];
const notificationData = [
  {
    status: 'read',
    reference: '59fe2b8e6ea9a344d4656498',
    created_on: '1510125753000',
    action_type: 'issue_assigned',
    message: 'Assigned user: WillKastner',
    id: '5a02f7096ea9a304f39d0006',
    reference_href: '#/app/issue/59fe2b8e6ea9a344d465649'
  },
  {
    status: 'read',
    reference: null,
    created_on: '1510125058000',
    action_type: null,
    message: 'Set due date to 2017-11-30 00:00:00:',
    id: '5a02f4526ea9a3040a18956a',
    reference_href: null
  },
  {
    status: 'unread',
    reference: null,
    created_on: '1510125058000',
    action_type: null,
    message: 'Set due date to 2017-11-30 00:00:00:',
    id: '5a02f4526ea9a3040a189569',
    reference_href: null
  },
  {
    status: 'read',
    reference: '5a02165d6ea9a304f21d65f1',
    created_on: '1510125041000',
    action_type: 'issue_assigned',
    message: 'Assigned user: WillKastner',
    id: '5a02f4416ea9a304f39d0005',
    reference_href: '#/app/issue/5a02165d6ea9a304f21d65f'
  },
  {
    status: 'unread',
    reference: '5a02165d6ea9a304f21d65f1',
    created_on: '1510125041000',
    action_type: 'issue_assigned',
    message: 'Assigned user: WillKastner',
    id: '5a02f4416ea9a304f39d0004',
    reference_href: '#/app/issue/5a02165d6ea9a304f21d65f'
  },
  {
    status: 'unread',
    reference: '5a02165d6ea9a304f21d65f1',
    created_on: '1510125041000',
    action_type: 'issue_assigned',
    message: 'Assigned user: BennyStacey',
    id: '5a02f4416ea9a304f39d0003',
    reference_href: '#/app/issue/5a02165d6ea9a304f21d65f'
  },
  {
    status: 'unread',
    reference: '59dbbe2a6ea9a30667a5363e',
    created_on: '1510063973000',
    action_type: 'issue_user_commented',
    message: 'User commented',
    id: '5a0205b56ea9a3041d1b0344',
    reference_href: '#/app/issue/59dbbe2a6ea9a30667a5363'
  },
  {
    status: 'unread',
    reference: '59dbbe2a6ea9a30667a5363e',
    created_on: '1510063973000',
    action_type: 'issue_user_commented',
    message: 'User commented',
    id: '5a0205b56ea9a3041d1b0343',
    reference_href: '#/app/issue/59dbbe2a6ea9a30667a5363'
  },
  {
    status: 'unread',
    reference: '59dbbe2a6ea9a30667a5363e',
    created_on: '1510063699000',
    action_type: 'issue_user_commented',
    message: 'User commented',
    id: '5a0204a36ea9a326e55e53e4',
    reference_href: '#/app/issue/59dbbe2a6ea9a30667a5363'
  },
  {
    status: 'unread',
    reference: '59dbbe2a6ea9a30667a5363e',
    created_on: '1510063699000',
    action_type: 'issue_user_commented',
    message: 'User commented',
    id: '5a0204a36ea9a326e55e53e3',
    reference_href: '#/app/issue/59dbbe2a6ea9a30667a5363'
  },
  {
    status: 'unread',
    reference: '59dbbe2a6ea9a30667a5363e',
    created_on: '1510063699000',
    action_type: 'issue_assigned',
    message: 'Assigned user: BennyStacey',
    id: '5a0204a36ea9a326e55e53e0',
    reference_href: '#/app/issue/59dbbe2a6ea9a30667a5363'
  },
  {
    status: 'unread',
    reference: '59dbbe2a6ea9a30667a5363e',
    created_on: '1510063699000',
    action_type: 'issue_assigned',
    message: 'Assigned user: BennyStacey',
    id: '5a0204a36ea9a326e55e53df',
    reference_href: '#/app/issue/59dbbe2a6ea9a30667a5363'
  },
  {
    status: 'unread',
    reference: '59dbbe2a6ea9a30667a5363e',
    created_on: '1510063588000',
    action_type: 'issue_user_commented',
    message: 'User commented',
    id: '5a0204346ea9a3041d1b0341',
    reference_href: '#/app/issue/59dbbe2a6ea9a30667a5363'
  },
  {
    status: 'unread',
    reference: '59dbbe2a6ea9a30667a5363e',
    created_on: '1510063588000',
    action_type: 'issue_user_commented',
    message: 'User commented',
    id: '5a0204346ea9a3041d1b0340',
    reference_href: '#/app/issue/59dbbe2a6ea9a30667a5363'
  },
  {
    status: 'read',
    reference: '59dbbe2a6ea9a30667a5363e',
    created_on: '1510063334000',
    action_type: 'issue_user_commented',
    message: 'User commented',
    id: '5a0203366ea9a3041d1b0337',
    reference_href: '#/app/issue/59dbbe2a6ea9a30667a5363'
  },
  {
    status: 'unread',
    reference: '59dbbe2a6ea9a30667a5363e',
    created_on: '1510063334000',
    action_type: 'issue_user_commented',
    message: 'User commented',
    id: '5a0203366ea9a3041d1b0336',
    reference_href: '#/app/issue/59dbbe2a6ea9a30667a5363'
  },
  {
    status: 'read',
    reference: '59dbbe2a6ea9a30667a5363e',
    created_on: '1510063334000',
    action_type: 'issue_assigned',
    message: 'Assigned user: JasonWiles',
    id: '5a0203366ea9a3041d1b0333',
    reference_href: '#/app/issue/59dbbe2a6ea9a30667a5363'
  },
  {
    status: 'unread',
    reference: '59dbbe2a6ea9a30667a5363e',
    created_on: '1510063334000',
    action_type: 'issue_assigned',
    message: 'Assigned user: JasonWiles',
    id: '5a0203366ea9a3041d1b0332',
    reference_href: '#/app/issue/59dbbe2a6ea9a30667a5363'
  },
  {
    status: 'read',
    reference: '5a010cf66ea9a304f39cfe36',
    created_on: '1510048170000',
    action_type: 'issue_user_commented',
    message: 'User commented',
    id: '5a01c7fa6ea9a3041d1b031e',
    reference_href: '#/app/issue/5a010cf66ea9a304f39cfe3'
  },
  {
    status: 'read',
    reference: null,
    created_on: '1509959976000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '5a006f786ea9a304f39cfd2c',
    reference_href: null
  },
  {
    status: 'read',
    reference: '59fdca106ea9a304e2747293',
    created_on: '1509790185000',
    action_type: 'issue_assigned',
    message: 'Assigned user: Kaileigh',
    id: '59fdca296ea9a344daa33dd8',
    reference_href: '#/app/issue/59fdca106ea9a304e274729'
  },
  {
    status: 'read',
    reference: null,
    created_on: '1509728685000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59fcd9ed6ea9a344d77e9f1f',
    reference_href: null
  },
  {
    status: 'read',
    reference: '59fcc9606ea9a304e274707f',
    created_on: '1509724460000',
    action_type: 'issue_assigned',
    message: 'Assigned user: Graham Hancock',
    id: '59fcc96c6ea9a344daa33da3',
    reference_href: '#/app/issue/59fcc9606ea9a304e274707'
  },
  {
    status: 'unread',
    reference: null,
    created_on: '1509461937000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59f8c7f16ea9a3092ab4a868',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1509108758000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59f364566ea9a305a120cb86',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1509108614000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59f363c66ea9a3059e4d7c0d',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1509108255000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59f3625f6ea9a3059e4d7c0c',
    reference_href: null
  },
  {
    status: 'unread',
    reference: null,
    created_on: '1508937315000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59f0c6a36ea9a30669bd7bff',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1508714944000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59ed62006ea9a30667a546e3',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1508508692000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59ea3c546ea9a306665f030b',
    reference_href: null
  },
  {
    status: 'unread',
    reference: null,
    created_on: '1508412806000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59e8c5c66ea9a306689d3395',
    reference_href: null
  },
  {
    status: 'unread',
    reference: null,
    created_on: '1508412579000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59e8c4e36ea9a306689d3394',
    reference_href: null
  },
  {
    status: 'unread',
    reference: null,
    created_on: '1508402521000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59e89d996ea9a306665f009a',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1508145959000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59e4b3676ea9a306665efd34',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1507661218000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59dd4de26ea9a30667a53866',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1507661138000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59dd4d926ea9a30667a53865',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1507661134000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59dd4d8e6ea9a306689d2916',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1507659276000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59dd464c6ea9a30667a53849',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1507644784000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59dd0db06ea9a306689d283c',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1507638790000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59dcf6466ea9a30669bd693a',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1507638265000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59dcf4396ea9a30667a5373f',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1507627178000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59dcc8ea6ea9a306689d2792',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1507627016000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59dcc8486ea9a30667a536e2',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1507626537000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59dcc6696ea9a306689d278f',
    reference_href: null
  },
  {
    status: 'read',
    reference: '59d3b0db6ea9a30669bd6145',
    created_on: '1507035639000',
    action_type: 'issue_resolved',
    message: 'Issue resolved',
    id: '59d3c2376ea9a304a34001ad',
    reference_href: '#/app/issue/59d3b0db6ea9a30669bd614'
  },
  {
    status: 'read',
    reference: null,
    created_on: '1507035634000',
    action_type: null,
    message: 'Set due date to 2017-10-05 00:00:00:',
    id: '59d3c2326ea9a304a34001ab',
    reference_href: null
  },
  {
    status: 'read',
    reference: '59d3b0db6ea9a30669bd6145',
    created_on: '1507035630000',
    action_type: 'issue_assigned',
    message: 'Assigned user: MattStrickland',
    id: '59d3c22e6ea9a306689d200a',
    reference_href: '#/app/issue/59d3b0db6ea9a30669bd614'
  },
  {
    status: 'read',
    reference: null,
    created_on: '1507034072000',
    action_type: null,
    message: 'Set due date to 2017-10-05 00:00:00:',
    id: '59d3bc186ea9a304a33fffac',
    reference_href: null
  },
  {
    status: 'read',
    reference: '59d2c6aa6ea9a304d0b88e47',
    created_on: '1507034068000',
    action_type: 'issue_assigned',
    message: 'Assigned user: MattStrickland',
    id: '59d3bc146ea9a306689d1fe1',
    reference_href: '#/app/issue/59d2c6aa6ea9a304d0b88e4'
  },
  {
    status: 'read',
    reference: '59d39b9b6ea9a304999d77ab',
    created_on: '1507026459000',
    action_type: 'issue_user_commented',
    message: 'User commented',
    id: '59d39e5b6ea9a3715862b42c',
    reference_href: '#/app/issue/59d39b9b6ea9a304999d77a'
  },
  {
    status: 'read',
    reference: '59d39b9b6ea9a304999d77ab',
    created_on: '1507026459000',
    action_type: 'issue_file_uploaded',
    message: 'File uploaded: 59d2c3246ea9a30415f57a42.jpeg',
    id: '59d39e5b6ea9a3715862b42a',
    reference_href: '#/app/issue/59d39b9b6ea9a304999d77a'
  },
  {
    status: 'read',
    reference: '59d29ef06ea9a30415f578b5',
    created_on: '1506961088000',
    action_type: 'issue_assigned',
    message: 'Assigned user: Kaileigh',
    id: '59d29f006ea9a304d0b88e08',
    reference_href: '#/app/issue/59d29ef06ea9a30415f578b'
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506592905000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59cd00c96ea9a304d0b88cbf',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506529868000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59cc0a8c6ea9a304d2d23d9f',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506529765000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59cc0a256ea9a304d2d23d9e',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506529613000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59cc098d6ea9a304d0b88ca7',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506529322000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59cc086a6ea9a304d1da4294',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506529105000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59cc07916ea9a304d441caf0',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506528863000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59cc069f6ea9a304d0b88ca6',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506528626000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59cc05b26ea9a304d1da4293',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506528396000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59cc04cc6ea9a304d441caef',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506512321000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59cbc6016ea9a304d1da4275',
    reference_href: null
  },
  {
    status: 'unread',
    reference: null,
    created_on: '1506505932000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59cbad0c6ea9a354744719df',
    reference_href: null
  },
  {
    status: 'read',
    reference: '59cabb4b6ea9a37c999a7fb2',
    created_on: '1506444061000',
    action_type: 'issue_assigned',
    message: 'Assigned user: ashley.collins@7g-enviro.com',
    id: '59cabb5d6ea9a35472c3c3b4',
    reference_href: '#/app/issue/59cabb4b6ea9a37c999a7fb'
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506421539000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59ca63636ea9a354744718d8',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506421503000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59ca633f6ea9a354714821f0',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506421131000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59ca61cb6ea9a354744718d1',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506420515000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59ca5f636ea9a354744718d0',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506420438000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59ca5f166ea9a354714821ef',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506417022000',
    action_type: null,
    message: 'Set due date to 2017-10-01 00:00:00:',
    id: '59ca51be6ea9a354d105ac10',
    reference_href: null
  },
  {
    status: 'read',
    reference: '59c91a246ea9a354737efc43',
    created_on: '1506415341000',
    action_type: 'issue_assigned',
    message: 'Assigned user: Kaileigh',
    id: '59ca4b2d6ea9a354744718b3',
    reference_href: '#/app/issue/59c91a246ea9a354737efc4'
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506412160000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59ca3ec06ea9a354737efe26',
    reference_href: null
  },
  {
    status: 'unread',
    reference: null,
    created_on: '1506381562000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59c9c73a6ea9a35472c3c2e1',
    reference_href: null
  },
  {
    status: 'unread',
    reference: null,
    created_on: '1506381555000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59c9c7336ea9a3547447189a',
    reference_href: null
  },
  {
    status: 'unread',
    reference: null,
    created_on: '1506378531000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59c9bb636ea9a354714821e0',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506353704000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59c95a686ea9a35471482185',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1506353609000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59c95a096ea9a354737efd1d',
    reference_href: null
  },
  {
    status: 'unread',
    reference: null,
    created_on: '1505751990000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59c02bf66ea9a354737ef35f',
    reference_href: null
  },
  {
    status: 'unread',
    reference: null,
    created_on: '1504878854000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59b2d9466ea9a3547447056c',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1504694101000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59b007956ea9a35471480af8',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1504693952000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59b007006ea9a35472c3ab4c',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1504690119000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59aff8076ea9a354737ee602',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1504184322000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59a84042b04b9205bc9365d7',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1504183945000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59a83ec9b04b9205635f0f3e',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1504183442000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59a83cd2b04b92054acc7176',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1504182656000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59a839c0b04b92054acc7175',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1504182508000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59a8392cb04b92054849cf8e',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1504182412000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59a838ccb04b92054849cf8d',
    reference_href: null
  },
  {
    status: 'read',
    reference: null,
    created_on: '1504181611000',
    action_type: 'background_job_notice',
    message: 'Your analytics report was generated successfully and is now being mailed...',
    id: '59a835abb04b920f0fe6d110',
    reference_href: null
  }
];

const notificationConfig = {
  action_types: [
    {
      toggles: {
        web: true,
        email: false,
        email_threshold: 3
      },
      action_type: 'agency_created'
    },
    {
      toggles: {
        web: true,
        email: false,
        email_threshold: 0
      },
      action_type: 'agency_metadata_created'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'agency_metadata_deleted'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'agency_metadata_updated'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'agency_updated'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'append_issues_group'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'auto_create_workorder'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'background_job_notice'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'contractor_created'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'contractor_metadata_created'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'contractor_metadata_deleted'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'contractor_metadata_updated'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'contractor_updated'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'create_group'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'create_workorder'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'file_added'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'file_archived'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'file_moved'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'file_removed'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'file_renamed'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'file_shared_via_email'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'file_shared_via_link'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'file_shared_via_user'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'file_uploaded'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'group_accepted'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'group_due_date_set'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'group_invoice_uploaded'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'group_rejected'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'group_status_change'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'group_user_assigned'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'group_user_unassigned'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'issue_assigned'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'issue_file_uploaded'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'issue_pending'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'issue_resolved'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'issue_user_commented'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_due_date'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_fee_paid'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_field_updated'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_file_uploaded'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_issued'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_metadata_created'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_metadata_deleted'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_metadata_updated'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_next_due_date'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_overdue'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_pending'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_upcoming'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_user_assigned'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'permit_user_commented'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'remove_issues_group'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'report_reviewed'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'route_create_workorder'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'test_completed'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'test_field_updated'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'test_file_uploaded'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'test_metadata_created'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'test_metadata_deleted'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'test_metadata_updated'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'test_next_planned_date'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'test_overdue'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'test_pending'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'test_planned_date'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'test_upcoming'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'test_user_assigned'
    },
    {
      toggles: {
        web: true,
        email: true,
        email_threshold: 0
      },
      action_type: 'test_user_commented'
    }
  ]
};

const tapesData = [
  {
    created_on: '1573149316000',
    client: '551c938013d5342d2d00f751',
    command: 'System Status',
    site: '551c93b013d5342d2d00f752',
    id: '5dc4a0d50813f0de87a4252d'
  },
  {
    created_on: '1573144211000',
    client: '551c938013d5342d2d00f751',
    command: 'Tank Leak Detection',
    site: '551c93b013d5342d2d00f752',
    id: '5dc48ce60813f0de87a42528'
  },
  {
    created_on: '1573143910000',
    client: '551c938013d5342d2d00f751',
    command: 'Tank Inventory',
    site: '551c93b013d5342d2d00f752',
    id: '5dc48bb80813f0de87a42527'
  },
  {
    created_on: '1573143310000',
    client: '551c938013d5342d2d00f751',
    command: 'Priority Alarm History',
    site: '551c93b013d5342d2d00f752',
    id: '5dc4896e0813f0de87a42526'
  },
  {
    created_on: '1573080010000',
    client: '551c938013d5342d2d00f751',
    command: 'Tank Diameter',
    site: '551c93b013d5342d2d00f752',
    id: '5dc3921c0813f0de87a42524'
  },
  {
    created_on: '1573079710000',
    client: '551c938013d5342d2d00f751',
    command: 'Deliveries',
    site: '551c93b013d5342d2d00f752',
    id: '5dc390fc0813f0de87a42523'
  },
  {
    created_on: '1573079410000',
    client: '551c938013d5342d2d00f751',
    command: 'Cleared Alarms',
    site: '551c93b013d5342d2d00f752',
    id: '5dc38fd90813f0de87a42522'
  },
  {
    created_on: '1573079109000',
    client: '551c938013d5342d2d00f751',
    command: 'Active Alarms',
    site: '551c93b013d5342d2d00f752',
    id: '5dc38e970813f0de87a42521'
  },
  {
    created_on: '1573078809000',
    client: '551c938013d5342d2d00f751',
    command: 'Non-Priority Alarm History',
    site: '551c93b013d5342d2d00f752',
    id: '5dc38d790813f0de87a42520'
  },
  {
    created_on: '1573062617000',
    client: '551c938013d5342d2d00f751',
    command: 'System Status',
    site: '551c93b013d5342d2d00f752',
    id: '5dc34e2a0813f0de87a4251b'
  }
];

const commandsData = [
  {
    site: '551c93b013d5342d2d00f752',
    command: 'i10100',
    description: '',
    name: 'System Status',
    id: '5d4b03fb1559951adb95f75a'
  },
  {
    site: '551c93b013d5342d2d00f752',
    command: 'i11100',
    description: '',
    name: 'Priority Alarm History',
    id: '5d4b03fb1559951adb95f75b'
  },
  {
    site: '551c93b013d5342d2d00f752',
    command: 'i11200',
    description: '',
    name: 'Non-Priority Alarm History',
    id: '5d4b03fb1559951adb95f75c'
  },
  {
    site: '551c93b013d5342d2d00f752',
    command: 'i11300',
    description: '',
    name: 'Active Alarms',
    id: '5d4b03fb1559951adb95f75d'
  },
  {
    site: '551c93b013d5342d2d00f752',
    command: 'i11400',
    description: '',
    name: 'Cleared Alarms',
    id: '5d4b03fb1559951adb95f75e'
  },
  {
    site: '551c93b013d5342d2d00f752',
    command: 'i20100',
    description: '',
    name: 'Tank Inventory',
    id: '5d4b03fb1559951adb95f75f'
  },
  {
    site: '551c93b013d5342d2d00f752',
    command: 'i20200',
    description: '',
    name: 'Deliveries',
    id: '5d4b03fb1559951adb95f760'
  },
  {
    site: '551c93b013d5342d2d00f752',
    command: 'i20300',
    description: '',
    name: 'Tank Leak Detection',
    id: '5d4b03fb1559951adb95f761'
  },
  {
    site: '551c93b013d5342d2d00f752',
    command: 'i60700',
    description: '',
    name: 'Tank Diameter',
    id: '5d4b03fb1559951adb95f762'
  }
];

const alarmsData = [
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Printer Error',
    issue_id: '5e5a1a2a042e01c2634dd1d9',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1582869600000',
    cleared: false,
    id: '5e5a1a29bdf43fa2fb1ca5fa',
    acknowledge_date: '1594727591000',
    category: 'System Alarm',
    clear_date: null,
    client_name: 'Test',
    acknowledged_by: 'Zhidkov, Roman',
    acknowledged: true,
    created_on: '1582945241000',
    state: 'Occurred',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Printer out of Paper',
    issue_id: '5e5a1a2aaa54c33ff02bf0d9',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1582869600000',
    cleared: false,
    id: '5e5a1a29bdf43fa2fb1ca5f9',
    acknowledge_date: null,
    category: 'System Alarm',
    clear_date: null,
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1582945241000',
    state: 'Occurred',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5e46d4848bac7c9b2eed1769',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'STP Sump',
    occurrence_date: '1581604380000',
    cleared: true,
    id: '5e46d483c13e5faa26ecaa5a',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1581604620000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1581682227000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5dfb665a63c23c23e06a807f',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Annular',
    occurrence_date: '1576660680000',
    cleared: true,
    id: '5dfb6659d0c11e3d49554bfc',
    acknowledge_date: '1581592283000',
    category: 'Liquid Sensor Alarm',
    clear_date: '1576660860000',
    client_name: 'Test',
    acknowledged_by: 'Zhidkov, Roman',
    acknowledged: true,
    created_on: '1576738825000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5dfb665ad6ea341ccac1a3d6',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'STP Sump',
    occurrence_date: '1576660680000',
    cleared: true,
    id: '5dfb6659d0c11e3d49554bfb',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1576660860000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1576738825000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank High Product Alarm',
    issue_id: '5dfb6c3526b925e9f797f0e9',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1576660380000',
    cleared: true,
    id: '5dfb6c34d6ea341ccac1a3d7',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1576660620000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1576740324000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank Overfill Alarm',
    issue_id: '5dfb665ad0c11e3d49554bff',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1576660380000',
    cleared: true,
    id: '5dfb6659d0c11e3d49554bfa',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1576660740000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1576738825000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5dfb6659d0c11e3d49554bfe',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Annular',
    occurrence_date: '1576660380000',
    cleared: true,
    id: '5dfb6659d0c11e3d49554bf8',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1576660620000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1576738825000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5dfb665ad6ea341ccac1a3d4',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'STP Sump',
    occurrence_date: '1576660380000',
    cleared: true,
    id: '5dfb6659d0c11e3d49554bf9',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1576660620000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1576738825000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5de5f2f0f6e3372ffed48b85',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1575331560000',
    cleared: true,
    id: '5de5f2f0eb7587e798101ea2',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1575368340000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1575333024000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5de4a044bc2eb665985495f7',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1575222600000',
    cleared: true,
    id: '5de4a044624bc3eb2dc312ac',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1575287580000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1575246324000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5de4a044fa28e70579a673ab',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1575168420000',
    cleared: true,
    id: '5de4a044624bc3eb2dc312ab',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1575200940000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1575246324000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5de34d98bc2eb665985495f5',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1575125100000',
    cleared: true,
    id: '5de34d97f6e3372ffed48b83',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1575157620000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1575159623000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5de0a840fa28e70579a673a5',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1574941020000',
    cleared: true,
    id: '5de0a840f6e3372ffed48b81',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1574951820000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1574986224000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5ddf55953484b0a395384033',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1574819820000',
    cleared: true,
    id: '5ddf5594b35c032be9aa1178',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1574962560000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1574899524000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5dc336c222c3eb7516f0252c',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'STP Sump',
    occurrence_date: '1572975240000',
    cleared: true,
    id: '5dc336c122c3eb7516f0252b',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1572975420000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1573056625000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5da0c33a8c567184685034dc',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Annular',
    occurrence_date: '1570722840000',
    cleared: true,
    id: '5da0c33a8c567184685034db',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1570723020000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1570802426000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank Periodic Leak Test Fail Alarm',
    issue_id: '5d9f708f648a2c573bb1957b',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1570686780000',
    cleared: true,
    id: '5d9f708e8c567184685034da',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1571439480000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1570715726000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5d83fe0cd87f0d1c00cb7295',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1568877780000',
    cleared: true,
    id: '5d83fe0cd48142f35d3644a0',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1568910120000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1568916940000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5d82ab5fd87f0d1c00cb726d',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1568792340000',
    cleared: true,
    id: '5d82ab5fd48142f35d36449f',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1568875320000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1568830239000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5d7010d40e6ec8e3bc0f80d4',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Annular',
    occurrence_date: '1567591920000',
    cleared: true,
    id: '5d7010d00e6ec8e3bc0f80d3',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1567592100000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1567611024000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5d61837c9a64082d7427dcff',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1566571800000',
    cleared: true,
    id: '5d61837ca65299f481430d18',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1566698100000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1566657340000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5d559a4544107f57aab4f85e',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1565805900000',
    cleared: true,
    id: '5d559a45d764c16dd2b3c704',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1568603880000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565876741000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5d4efcea65c757bb7b1cf655',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1565371800000',
    cleared: true,
    id: '5d4efce990a1ca3a33ef17a6',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1566536280000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565443241000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Printer Error',
    issue_id: '5d4b04f7680fe72b562fad67',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1562692980000',
    cleared: true,
    id: '5d4b04f5c40e9fdc645341a2',
    acknowledge_date: null,
    category: 'System Alarm',
    clear_date: '1562692980000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183157000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Printer Error',
    issue_id: '5d4b04f7c40e9fdc645341ab',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1562692920000',
    cleared: true,
    id: '5d4b04f4c40e9fdc645341a1',
    acknowledge_date: null,
    category: 'System Alarm',
    clear_date: '1562692980000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183156000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Printer out of Paper',
    issue_id: '5d4b04f7190e2d9ff1808115',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1562692920000',
    cleared: true,
    id: '5d4b04f4c40e9fdc645341a0',
    acknowledge_date: null,
    category: 'System Alarm',
    clear_date: '1562692980000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183156000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Printer Error',
    issue_id: '5d4b04f6190e2d9ff1808113',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1562683200000',
    cleared: true,
    id: '5d4b04f4c40e9fdc6453419f',
    acknowledge_date: null,
    category: 'System Alarm',
    clear_date: '1562683260000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183156000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Printer out of Paper',
    issue_id: '5d4b04f6680fe72b562fad65',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1562683200000',
    cleared: true,
    id: '5d4b04f4c40e9fdc6453419e',
    acknowledge_date: null,
    category: 'System Alarm',
    clear_date: '1562683260000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183156000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Printer Error',
    issue_id: '5d4b04f6680fe72b562fad63',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1561624320000',
    cleared: true,
    id: '5d4b04f4c40e9fdc6453419c',
    acknowledge_date: null,
    category: 'System Alarm',
    clear_date: '1561624320000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183156000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Printer out of Paper',
    issue_id: '5d4b04f6c40e9fdc645341a9',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1561624320000',
    cleared: true,
    id: '5d4b04f4c40e9fdc6453419d',
    acknowledge_date: null,
    category: 'System Alarm',
    clear_date: '1561624320000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183156000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5d4b04f1190e2d9ff18080f4',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'STP Sump',
    occurrence_date: '1561623720000',
    cleared: true,
    id: '5d4b04e9190e2d9ff18080da',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1561623900000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183145000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank High Product Alarm',
    issue_id: '5d4b04f6190e2d9ff180810f',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1561622760000',
    cleared: true,
    id: '5d4b04f4c40e9fdc6453419b',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1561622760000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183156000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5d4b04f6680fe72b562fad61',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1561044360000',
    cleared: true,
    id: '5d4b04f4c40e9fdc6453419a',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1561132560000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183156000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5d4b04f1680fe72b562fad47',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'STP Sump',
    occurrence_date: '1560934740000',
    cleared: true,
    id: '5d4b04e9190e2d9ff18080d9',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1560934920000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183145000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5d4b04f5190e2d9ff180810c',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1560488340000',
    cleared: true,
    id: '5d4b04f3c40e9fdc64534199',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1560954060000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183155000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank CSLD Rate Increase Warning',
    issue_id: '5d4b04f5680fe72b562fad5f',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1560267360000',
    cleared: true,
    id: '5d4b04f3c40e9fdc64534198',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1560409380000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183155000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5d4b04f1190e2d9ff18080f2',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Annular',
    occurrence_date: '1558084200000',
    cleared: true,
    id: '5d4b04e9190e2d9ff18080d7',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1560934860000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183144000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5d4b04f1680fe72b562fad45',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'STP Sump',
    occurrence_date: '1558084200000',
    cleared: true,
    id: '5d4b04e9190e2d9ff18080d8',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1560934680000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183145000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank Periodic Leak Test Fail Alarm',
    issue_id: '5d4b04f0680fe72b562fad43',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1557870060000',
    cleared: true,
    id: '5d4b04e8190e2d9ff18080d6',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1559580900000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183144000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank Periodic Leak Test Fail Alarm',
    issue_id: '5d4b04f0190e2d9ff18080f0',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1557724800000',
    cleared: true,
    id: '5d4b04e8190e2d9ff18080d5',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1558410000000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183144000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5d4b04ef190e2d9ff18080ee',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Annular',
    occurrence_date: '1554282360000',
    cleared: true,
    id: '5d4b04e8190e2d9ff18080d3',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1557395340000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183144000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5d4b04ef680fe72b562fad41',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'STP Sump',
    occurrence_date: '1554282360000',
    cleared: true,
    id: '5d4b04e8190e2d9ff18080d4',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1557395340000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183144000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Sensor Fuel Alarm',
    issue_id: '5d4b04ef680fe72b562fad3f',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Annular',
    occurrence_date: '1553683860000',
    cleared: true,
    id: '5d4b04e8190e2d9ff18080d2',
    acknowledge_date: null,
    category: 'Liquid Sensor Alarm',
    clear_date: '1553693160000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183144000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 2
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank High Water Warning',
    issue_id: '5d4b04f5680fe72b562fad5d',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1553683740000',
    cleared: true,
    id: '5d4b04f3c40e9fdc64534197',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1553693160000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183155000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  },
  {
    site_name: 'Test Site - TLS 300 - DNR',
    alarm_type: 'Tank High Water Alarm',
    issue_id: '5d4b04ef190e2d9ff18080ec',
    site: '551c93b013d5342d2d00f752',
    sensor_category: 'Other',
    occurrence_date: '1553683740000',
    cleared: true,
    id: '5d4b04e8190e2d9ff18080d1',
    acknowledge_date: null,
    category: 'Tank Alarm',
    clear_date: '1553693160000',
    client_name: 'Test',
    acknowledged_by: null,
    acknowledged: false,
    created_on: '1565183144000',
    state: 'Cleared',
    client: '551c938013d5342d2d00f751',
    sensor: 1
  }
];

const calendar = {
  data: {
    '2019/07/05': {
      test: [],
      inspection_result: ['4703'],
      route_plan: [
        '2706762',
        '2741798',
        "1854-11 - Harrisburg Int'l Airport - RAC Hertz",
        'GIANT #350',
        'GIANT #354',
        'IL0078',
        '4706880',
        'Sheetz - 359',
        'MA0042',
        '4703',
        '6309',
        '2721585',
        '7340',
        '4938',
        '4731',
        '6347',
        'Sheetz - 470',
        '4706881',
        'Sheetz - 363',
        'Sheetz - 396',
        'MN0017',
        'IL0130',
        '7045',
        'FedEx Ground # 730',
        '4706795',
        'Sheetz - 511',
        '5280',
        '2741791',
        'Sheetz - 222',
        'Sheetz - 364',
        'Sheetz - 211',
        '6408',
        'FedEx Ground # 553',
        'Sheetz - 343',
        '6582',
        'MN0019',
        'Sheetz - 572',
        '4706205',
        '2741793',
        '6682',
        '6090',
        '2704336',
        'Enmarket - 831',
        '7189',
        'IL0073',
        '3338',
        'Ryder Truck Rental #0070',
        '8104',
        '6661',
        'Sheetz - 534',
        'MA0037',
        '2704472',
        '3637',
        '7177',
        'FedEx Ground # 753',
        '4706760',
        '4706885',
        '3628',
        '2706763',
        'Sheetz - 351',
        'IL0095',
        '6084',
        'IL0092',
        '6684',
        '6672',
        '4786',
        'Enmarket - 560',
        '2706764',
        '6014',
        '8142',
        '2721583',
        '6443',
        '1699',
        '717',
        'FedEx Ground # 176',
        'Enmarket - 842',
        '4706729',
        '2741794',
        '7326',
        'Sheetz - 662',
        '2741786',
        'Ryder Truck Rental #0558',
        'IL0079',
        '8289',
        'PA0028',
        '1726',
        '4545',
        '4706302',
        'MA0043',
        '5371',
        'MA0014',
        'PA0140',
        'MN0011',
        'Sheetz - 566',
        'MA0033',
        'FedEx Ground # 552',
        '4706882',
        'Sheetz - 418',
        'IL0133',
        '4706757',
        '4706714',
        'Enmarket - 851',
        '6581',
        "2170-11 - Salt Lake City Int'l Airport - RAC Hertz",
        'Sheetz - 302',
        'MA0046',
        'Enmarket - 850',
        '4706308',
        '2741787',
        'MA0041',
        '2707741',
        'Sheetz - 564',
        'FedEx Ground # 752',
        '2704275',
        '8117',
        'FedEx Ground # 760',
        '4689-20 - Salt Lake City AP HLE/Lyft',
        '4706823',
        '4706303',
        '6429',
        '2741796',
        'Sheetz - 616',
        'Sheetz - 569',
        '4676',
        'FLORIDA CITRUS CENTER #60',
        '8253',
        'IL0136',
        "1328-11 - Jacksonville Int'l Airport - RAC Hertz"
      ],
      permit: []
    },
    '2019/07/04': {
      test: [],
      inspection_result: [
        '6658',
        '1878',
        'Sheetz - 370',
        '6070',
        '6414',
        '2721564',
        'IL0071',
        '4388',
        '2721580',
        '4705637',
        'Enmarket - 300',
        '2721572',
        'Sheetz - 523',
        'Sheetz - 395',
        '4705671',
        'Sheetz - 651',
        'PA0053',
        '6023',
        '6940-01 - Capitol City - HLE',
        'Sheetz - 603',
        'Sheetz - 572',
        '1902',
        'JF 263 Appomattox',
        'IL0082',
        '6236',
        'Sheetz - 627',
        '4705640',
        '2721565',
        '2708251',
        "82901 - Albuquerque Int'l Sunport - DTAG Thrifty",
        '6345',
        'FedEx Ground # 603',
        "2184-11 - Albuquerque Int'l Sunport - RAC Hertz",
        '4705658',
        'JF Store 884 Greenwood',
        '4705669',
        'Sheetz - 297',
        '4705724',
        '6043',
        '6352',
        '2721567',
        '6228',
        'GIANT #351',
        '2721578',
        'Sheetz - 449',
        'Sheetz - 364',
        '2721573',
        '2721579',
        '5494',
        '4705670',
        '4705645',
        'IL0090',
        'Sheetz - 628',
        'Sheetz - 408',
        '2023',
        '6348',
        '4781',
        'Sheetz - 291',
        '6962',
        '4705672',
        '2707736',
        '6365',
        'Sheetz - 463',
        'PA0436',
        '8191',
        'JMM Global'
      ],
      route_plan: [
        '6658',
        '1878',
        'Sheetz - 370',
        "1875-11 - Wilkes-Barre/Scranton Int'l Airport - RAC",
        'MA0045',
        '2721564',
        'IL0071',
        'Sheetz - 408',
        '4388',
        '4706313',
        '2721580',
        'Enmarket - 300',
        '2721572',
        'Sheetz - 523',
        'Sheetz - 395',
        'MA0039',
        '6352',
        '8283',
        'Sheetz - 651',
        '8259',
        '6023',
        'Sheetz - 491',
        '6940-01 - Capitol City - HLE',
        'Sheetz - 603',
        '4706301',
        '1902',
        'JF 263 Appomattox',
        'IL0082',
        'MA0051',
        '6236',
        'MA0082',
        'Sheetz - 627',
        '4872',
        '6453',
        '2721565',
        '4706218',
        '2708251',
        '4706213',
        "82901 - Albuquerque Int'l Sunport - DTAG Thrifty",
        '6345',
        'FedEx Ground # 603',
        "2184-11 - Albuquerque Int'l Sunport - RAC Hertz",
        'JF Store 884 Greenwood',
        '4706225',
        'Sheetz - 297',
        '6962',
        '6043',
        '2721567',
        '6188',
        '4706306',
        '6228',
        '2707736',
        '2721578',
        'MA0038',
        'Sheetz - 449',
        '2721573',
        '4706309',
        '2721579',
        '5494',
        '4706228',
        '4706300',
        'Sheetz - 628',
        "1636-10 - Austin-Bergstrom Int'l Airport - RAC",
        '4706307',
        '7016',
        '2023',
        '6348',
        '4781',
        '4706310',
        'MA0050',
        'Sheetz - 291',
        '6365',
        'PA0436',
        'IL0090',
        '8191',
        'JMM Global'
      ],
      permit: []
    },
    '2019/07/07': {
      test: [],
      inspection_result: [],
      route_plan: [
        'Sheetz - 541',
        '7224',
        'Enmarket - 878',
        '3568',
        '4197',
        '7342',
        '6055',
        'Sheetz - 269',
        'Sheetz - 472',
        '6644',
        'Sheetz - 478',
        '8291',
        '2699',
        '5267',
        '4503',
        '8194',
        '2731',
        '6606',
        'Enmarket - 880',
        '4830',
        '6685',
        '6218',
        '7294',
        '4 Way',
        '6031',
        '1714',
        '4776',
        '2707740',
        '8262',
        '5741',
        'Enmarket - 874',
        '6663',
        '6683',
        '4056',
        '6878',
        '4704199',
        '8107',
        '6080',
        '6189',
        '1522',
        '5716',
        '4778',
        '4130',
        '5805',
        '6016',
        '7013',
        '6212',
        '2368',
        '2721581',
        '6779',
        '4955',
        '3327',
        '4936',
        '4661',
        '1653',
        '5925',
        "Bradley's Marathon",
        '2721555',
        'Sheetz - 522',
        'Kader Market Inc',
        'Enmarket - 870',
        'Sheetz - 586',
        '4055',
        '7308',
        '8189',
        'Sheetz - 384',
        '2707735',
        '2474',
        '4958',
        '2769',
        "1460-11 - Charleston Int'l Airport - RAC",
        '4780',
        '4718',
        '3202',
        '7232',
        '8282',
        '7185',
        "1875-21 - Lehigh Valley Int'l Airport - RAC Hertz",
        '2707737',
        'Waterfront',
        '4704198',
        'Sheetz - 538',
        '6894',
        '2721569',
        'Sheetz - 571',
        '8241',
        '5323',
        '2721570',
        '4706314',
        '2707738',
        'Enmarket - 891',
        '3204',
        'Sheetz - 289',
        '2708242',
        'Enmarket - 869',
        'Sheetz - 655',
        'M&M',
        'Sheetz - 663',
        '4730',
        '6419'
      ],
      permit: []
    },
    '2019/07/06': {
      test: [],
      inspection_result: [],
      route_plan: [
        '2721559',
        'Sheetz - 398',
        '4706745',
        '4706826',
        '8138 - OLD',
        'FedEx Ground # 276',
        'PA0203',
        'Sheetz - 219',
        '4706832',
        '2706757',
        '8278',
        'Sheetz - 355',
        '6359',
        'Bobops #14',
        '4706733',
        'MN0030',
        '701',
        '2705997',
        '2705984',
        'Sheetz - 495',
        '2707742',
        'PA0194',
        'Sheetz - 237',
        'Sheetz - 218',
        'Beltline Shell',
        '6244',
        'Austinville Shell',
        '6254',
        '2721586',
        '6604',
        'Sheetz - 198',
        'Corner Shell',
        'FedEx Ground # 293',
        '2466',
        'Sheetz - 614',
        '8210',
        'Sheetz - 647',
        'Enmarket - 120',
        'IL0094',
        "1864-11 - Greenville-Spartanburg Int'l Airport - RAC",
        '6686',
        '7026',
        'Bobops #2',
        'Enmarket - 881',
        'Sheetz - 332',
        '2705998',
        'Sheetz - 411',
        '2488',
        '4706825',
        '4785',
        '4443',
        'Sheetz - 570',
        'Sheetz - 412',
        '2944',
        '4831',
        '5364',
        'Sheetz - 453',
        'PA0186',
        'MN0040',
        'Sheetz - 611',
        '1534-11 - Hertz-Gerald R. Ford International - RAC Hertz',
        '2706758',
        '1440',
        'Ryder Truck Rental #0674',
        'Danville Grocery',
        'FedEx Ground # 841',
        'PA0220',
        'Sheetz - 574',
        '4445',
        'Enmarket - 829',
        '4706749',
        'Sheetz - 642',
        '2706760',
        'IL0097',
        '6006',
        'Sheetz - 578',
        'IL0098',
        '8183',
        'PA0210',
        'Riverbridge Shell',
        "40009 - Orlando-Sanford Int'l Airport - DTAG Dollar",
        '8196',
        '2708243',
        '6267',
        'FedEx Ground # 850',
        'FedEx Ground # 858',
        'IL0091',
        'Enmarket - 845',
        '4706207',
        'PA0218',
        '6319',
        '4452',
        'Enmarket - 075',
        '4390',
        'Sheetz - 646',
        '2706759',
        '4706208',
        '4911',
        'Ryder Truck Rental - 129',
        'Sheetz - 312',
        '6042',
        '6562',
        'Bobops #1',
        'Sheetz - 701',
        '4901'
      ],
      permit: []
    },
    '2019/07/01': {
      test: ['Test Site - TLS 300 - DNR'],
      inspection_result: [
        '8168',
        'FedEx Ground # 981',
        '2723731',
        '4706720',
        'County Yard',
        '4705712',
        "52201 - Tucson Int'l Airport - DTAG Dollar",
        'Sheetz - 454',
        'M & M Market #4',
        '2723835',
        'Sheetz - 276',
        'Sheetz - 509',
        '4879',
        'Sheetz - 624',
        "1425-00 - Little Rock Int'l Airport - DTAG Thrifty ",
        '4705571',
        '1898-20 - Ronald Reagan Washington National Airport - RAC Hertz (Maint.)',
        '4801',
        'Sheetz - 658',
        '4706803',
        '3472',
        '4787',
        '3511',
        '2723733',
        '4706719',
        "1857-01 - Norfolk Int'l Airport - RAC Hertz",
        'IL0070',
        'Sheetz - 378',
        '4705714',
        'GIANT FOOD & FUEL #126',
        'FedEx Ground # 461',
        '4706738',
        '2431',
        'Enmarket - 899',
        '6655',
        '4710',
        '5780',
        '8135',
        "1856-14 - Newport News-Williamsburg Int'l Airport - RAC Hertz",
        '6386',
        'Enmarket - 897',
        'GIANT OIL #179',
        'Sheetz - 213',
        '6311',
        'Ryder Truck Rental #0134',
        'Enmarket - 861',
        '6448',
        '5488',
        'FedEx Ground # 762',
        '8169',
        '6069',
        '4738',
        '6351',
        '4705616',
        '2723727',
        '4705713',
        '6692',
        'MN0034',
        '4705252',
        "82201 - Tucson Int'l Airport - DTAG Thrifty",
        '236',
        '6876',
        'IL0115',
        "1730-11 - Burlington Int'l Airport - RAC Hertz",
        '6401',
        '6583',
        '6379',
        '1518',
        '4706788',
        '2723822',
        "2182-11 - Tucson Int'l Airport - RAC Hertz (Maint.)",
        '288',
        '4706711',
        'FedEx Ground # 591',
        '2723799',
        '2709070',
        '2723833',
        '163',
        '4674',
        '6018',
        '4705715',
        '6312',
        '6077',
        '4706831',
        'Sheetz - 372',
        'Majette',
        'GIANT #175',
        'Summit Beach Resort',
        'FedEx Ground # 972',
        '4706735',
        '4705206',
        'MN0031',
        '6377',
        '6456',
        '8265',
        'GIANT #132',
        "2182-11A - Tucson Int'l Airport - RAC Hertz (QTA) ",
        'FedEx Ground # 462',
        'Sheetz - 666',
        'IL0083',
        '2723821',
        'Enmarket - 860',
        '4705689',
        '4705564',
        '6368',
        '4706765',
        'IL0116',
        'Sheetz - 636',
        'Enmarket - 898',
        '8151',
        '4711',
        '4705651',
        '7199',
        '4705204',
        '8224',
        '2723725',
        'Sheetz - 434',
        'IL0109',
        '8275',
        'Enmarket - 896',
        '4705693',
        '3265',
        '1810-11 - Ronald Reagan Washington National Airport - RAC Hertz (QTA)',
        'Enmarket - 858',
        '6505',
        '4490',
        '4706736',
        '4706810'
      ],
      route_plan: [
        '8168',
        'FedEx Ground # 981',
        '2723731',
        '4706720',
        'GIANT #363',
        'County Yard',
        '4705712',
        'Summit Beach Resort',
        "52201 - Tucson Int'l Airport - DTAG Dollar",
        'Sheetz - 454',
        'M & M Market #4',
        '5188',
        '2723835',
        '4705638',
        'Sheetz - 346',
        '7339',
        'Sheetz - 276',
        'Sheetz - 509',
        '3635',
        'Sheetz - 624',
        '236',
        '4705571',
        '1458-11 - Augusta Regional Airport - RAC',
        '4801',
        '3619',
        'Sheetz - 658',
        '4706803',
        'GIANT #175',
        '4787',
        '3511',
        '2723733',
        '4706719',
        "1857-01 - Norfolk Int'l Airport - RAC Hertz",
        'IL0070',
        "2182-11A - Tucson Int'l Airport - RAC Hertz (QTA)",
        'Sheetz - 378',
        '4705714',
        'GIANT FOOD & FUEL #126',
        'FedEx Ground # 461',
        '4706738',
        '2431',
        '4710',
        '5780',
        'FedEx Ground # 373',
        "1856-14 - Newport News-Williamsburg Int'l Airport - RAC Hertz",
        '6386',
        "1730-11 - Burlington Int'l Airport - RAC Hertz",
        'FedEx Ground # 591',
        'GIANT OIL #179',
        'Sheetz - 213',
        '6311',
        'Sheetz - 323',
        'Enmarket - 833',
        'Ryder Truck Rental #0134',
        '6448',
        '5488',
        '4206',
        'FedEx Ground # 762',
        '8169',
        '6069',
        '4738',
        '6351',
        '4705616',
        '8115',
        '8275',
        '4705713',
        '6692',
        'MN0034',
        '4705252',
        "82201 - Tucson Int'l Airport - DTAG Thrifty",
        '6876',
        '6363',
        'Sheetz - 581',
        'IL0115',
        '6583',
        '6379',
        '1518',
        '4706788',
        '2465',
        '2723822',
        "2182-11 - Tucson Int'l Airport - RAC Hertz (Maint.)",
        '288',
        '4706711',
        'GIANT OIL #362',
        '4705673',
        'RH Davis - 12 Well Road Exxon',
        '1750-12 - Elmira-Corning Regional Airport - RAC Hertz',
        '2723799',
        '2709070',
        '2723833',
        '163',
        '4674',
        '6018',
        '4705715',
        "1425-00 - Little Rock Int'l Airport - DTAG Thrifty",
        '4705322',
        '6312',
        'Warthen Express',
        '6077',
        '4705639',
        '4706831',
        'Sheetz - 372',
        'Majette',
        'Enmarket - 837',
        'Sheetz - 186',
        'FedEx Ground # 972',
        '4706735',
        '4705635',
        '2385',
        '4705206',
        'MN0031',
        '2723727',
        '6377',
        '6456',
        '8265',
        'GIANT #132',
        'FedEx Ground # 462',
        'IL0083',
        '2723821',
        '4705564',
        'Blanding Exxon',
        '6368',
        'Sheetz - 474',
        '4706765',
        'IL0116',
        'Sheetz - 636',
        '4705648',
        '4711',
        '4705651',
        '7199',
        '4705204',
        '8224',
        '2723725',
        'Sheetz - 434',
        'IL0109',
        '4692',
        '4705693',
        '3265',
        '6505',
        'BP-COLLEGE ROAD # 383',
        '4490',
        '4706736',
        '4706810'
      ],
      permit: []
    },
    '2019/07/03': {
      test: [],
      inspection_result: [
        'Sheetz - 565',
        '5726',
        '1066-00 - Hertz Data Center - CORP',
        '6524',
        '1627-11 - Will Rogers World Airport - RAC Hertz',
        'WI0072',
        '6092',
        'WI0049',
        '4706707',
        'Enmarket - 848',
        '6074',
        'GIANT #353',
        'Sheetz - 588',
        '2721577',
        '8283',
        'GIANT #364',
        'PA0248',
        '7016',
        'WI0055',
        'Ryder Truck Rental #0870',
        '6323',
        '6286',
        '4706768',
        'GIANT OIL #131',
        'Sheetz - 338',
        'Sheetz - 238',
        '6313',
        '8298',
        '3427',
        'WI0057',
        '4705368',
        '4705613',
        'Ryder Truck Rental #1526',
        '3414',
        'Sheetz - 285',
        'GIANT #130',
        'GIANT #125',
        '3093',
        'GIANT #355',
        '2721575',
        '5143',
        '5507 - Hilton Head Airport - HLE',
        'GIANT OIL #129',
        '3450',
        '4706802',
        '8277',
        '4706730',
        '4706870',
        '4705643',
        '6030',
        'Sheetz - 386',
        '4705632',
        '4705592',
        '58',
        '4705417',
        "1636-10 - Austin-Bergstrom Int'l Airport - RAC",
        '1705-11 - Manchester-Boston Regional Airport - RAC Hertz',
        'Sheetz - 322',
        'GIANT #363',
        'Circle K Normandy',
        'GIANT #357',
        'WI0060',
        'Sheetz - 410',
        '6165',
        '8236',
        '6535',
        'PA0143',
        '1045-00A - Hertz Admin Center - Corp',
        'Sheetz - 341',
        'Sheetz - 432',
        '245',
        '2723826',
        'Sheetz - 580',
        '4705634',
        '3816',
        'FedEx Ground # 282',
        '4961',
        '6064',
        'GIANT #365',
        'FedEx Ground # 608',
        '4706717',
        '2721571',
        '7030',
        '4528',
        '4706713',
        '3170',
        '4706798',
        'Sheetz - 374',
        'PA0120',
        '8186',
        'IL0088',
        '7390',
        '2481',
        '6424',
        'Sheetz - 657',
        '3619',
        '8134',
        'GIANT #198',
        'GIANT #361',
        '3169',
        '3897',
        '6188',
        'Enmarket - 857',
        '4705722',
        'Sheetz - 436',
        '4706737',
        '1749',
        'WI0076',
        '4705416',
        'Sheetz - 323',
        '8259',
        'WI0048',
        'IL0118',
        '4692',
        'FedEx Ground # 170',
        '6082',
        '8175',
        '4579',
        'Sheetz - 512',
        '3739',
        '7251',
        'JMM Global 2',
        'PA0401',
        'FedEx Ground # 274',
        'Sheetz - 303',
        '2508',
        'PA0433',
        'Sheetz - 216',
        '2721576',
        '4705720',
        'PA0003',
        '3449',
        '4994',
        '7055',
        '1057-00 - Hertz Reservation Center - CORP',
        '4705642',
        "1868-11 - Piedmont Triad Int'l Airport - RAC Hertz",
        '6453',
        'Sheetz - 235',
        '4705633',
        '4705410',
        '4705500',
        'Sheetz - 321',
        '2741777',
        '4872',
        'Sheetz - 491',
        '6338',
        '3653',
        'PA0053',
        '2721563',
        '2862',
        '4705647',
        '3635',
        '4749',
        '4742'
      ],
      route_plan: [
        '5726',
        '1066-00 - Hertz Data Center - CORP',
        '6524',
        '1627-11 - Will Rogers World Airport - RAC Hertz',
        'WI0072',
        '6092',
        'WI0049',
        '4706707',
        'Enmarket - 848',
        '4705669',
        'Sheetz - 463',
        '6074',
        'Sheetz - 588',
        'WI0055',
        'Ryder Truck Rental #0870',
        '6323',
        '6286',
        '4706717',
        'GIANT OIL #131',
        'Sheetz - 338',
        'Sheetz - 238',
        '6313',
        '5776',
        '8298',
        'WI0057',
        'Sheetz - 221',
        '4705368',
        '4705613',
        'Ryder Truck Rental #1526',
        '3414',
        '4705637',
        'Sheetz - 285',
        'GIANT #130',
        'GIANT #125',
        '3093',
        '2721575',
        '4705670',
        '5507 - Hilton Head Airport - HLE',
        'GIANT OIL #129',
        '4706802',
        '8277',
        '6414',
        'Enmarket - 857',
        '4586',
        '4706870',
        'Sheetz - 386',
        '4705592',
        '6730',
        '58',
        '4705417',
        '1705-11 - Manchester-Boston Regional Airport - RAC Hertz',
        'Sheetz - 322',
        '4742',
        'GIANT OIL #366',
        '6070',
        '3427',
        'WI0060',
        '4706798',
        '6203',
        '6165',
        '2721577',
        '8236',
        '6535',
        'PA0143',
        '1045-00A - Hertz Admin Center - Corp',
        'Sheetz - 341',
        'Sheetz - 432',
        '245',
        '3450',
        '2723826',
        'Sheetz - 580',
        '4705634',
        '3816',
        'FedEx Ground # 282',
        '4961',
        '6064',
        'FedEx Ground # 608',
        '2721571',
        '6207',
        '4706713',
        '3170',
        'Sheetz - 374',
        'PA0120',
        '8186',
        'IL0088',
        '7390',
        '2481',
        '6424',
        'Sheetz - 657',
        '4706768',
        '8134',
        '3897',
        '4706730',
        '4705722',
        'GIANT OIL #123',
        '4706737',
        '1749',
        '4705640',
        '6020',
        'WI0076',
        '4705416',
        'WI0048',
        'IL0118',
        'FedEx Ground # 170',
        '6082',
        '8175',
        'Sheetz - 565',
        '4579',
        'Sheetz - 512',
        '4705671',
        '3739',
        '7251',
        'Enmarket - 862',
        'JMM Global 2',
        'PA0401',
        'Circle K Mobil',
        'Sheetz - 303',
        '4705645',
        '2508',
        'Sheetz - 216',
        '2721576',
        '4705720',
        'PA0003',
        '3449',
        '4994',
        '7055',
        '1057-00 - Hertz Reservation Center - CORP',
        "1868-11 - Piedmont Triad Int'l Airport - RAC Hertz",
        'Sheetz - 235',
        '1862-11 - Columbia Metropolitan Airport - RAC Hertz (Maint.)',
        '4705672',
        'Enmarket - 895',
        '4705410',
        '4705500',
        '4818',
        'Sheetz - 321',
        '2741777',
        '6338',
        '3653',
        '6073',
        '3169',
        '2721563',
        '6099',
        'FedEx Ground # 274',
        '2862',
        '4705647',
        '4749'
      ],
      permit: []
    },
    '2019/07/02': {
      test: [
        '6245',
        '7021',
        '7030',
        "1740-21 - Bangor Int'l Airport - RAC Hertz",
        'FedEx Ground # 722',
        '4705253',
        'Sheetz - 518',
        '2741790',
        '6083',
        '8266',
        '8112',
        '6502',
        'PA0234',
        'Sheetz - 205',
        '3043',
        'PA0248',
        '5791',
        'IL0141',
        'GIANT #364',
        '4706806'
      ],
      inspection_result: [
        '4705251',
        'Sheetz - 489',
        '5378',
        '7347',
        '6459',
        '6354',
        'Sheetz - 258',
        '6929',
        'Sheetz - 286',
        '4706748',
        'GIANT #122',
        '4705721',
        'WI0071',
        '4705567',
        'WI0005',
        "1740-11 - Portland Int'l Jetport - RAC",
        'Sheetz - 460',
        '8280',
        '6020',
        '3885',
        '6333',
        '4706865',
        '4706744',
        '4705644',
        '5776',
        '5188',
        'Sheetz - 221',
        '5782',
        '4706830',
        '4784',
        '6578',
        '4706804',
        '2834',
        '4705501',
        'Sheetz - 474',
        '2723824',
        '2723726',
        '4705638',
        '1868',
        'Sheetz - 595',
        '2741776',
        '4706725',
        'Sheetz - 350',
        '8286',
        '2723823',
        '1862-11 - Columbia Metropolitan Airport - RAC Hertz (Maint.)',
        '1480-11 - Chattanooga Metropolitan Airport (Lovell Field) - RAC Hertz',
        '4705631',
        'Sheetz - 243',
        '4705648',
        'GIANT #117',
        '4705621',
        'Sheetz - 201',
        '4706747',
        '6339',
        '6025',
        '6203',
        '4706806',
        'WI0054',
        'BP-COLLEGE ROAD # 383',
        'Sheetz - 366',
        '2723729',
        'WI0075',
        '4705278',
        '6036',
        'Enmarket - 862',
        '5291',
        '4706727',
        '6085',
        '6363',
        '4705641',
        '8153',
        '3880',
        '4705623',
        'WI0064',
        'Sheetz - 195',
        '4705685',
        'Enmarket - 895',
        'GIANT OIL #362',
        "1740-21 - Bangor Int'l Airport - RAC Hertz",
        'WI0056',
        'IL0074',
        'Sheetz - 484',
        'Sheetz - 270',
        'Sheetz - 579',
        '6403',
        '4705443',
        '1531',
        '4705611',
        "1476-11 - Savannah-Hilton Head Int'l Airport - RAC Hertz",
        '4705673',
        'GIANT OIL #123',
        '6415',
        'Sheetz - 426',
        '6781',
        '4671',
        '7014',
        '8296',
        '6207',
        '2741775',
        '6246',
        '6037',
        'RH Davis - 12 Well Road Exxon',
        'Sheetz - 581',
        'CEFCO #487',
        '4705639',
        'Sheetz - 518',
        '2741797',
        '4470',
        '2723730',
        'Sheetz - 356',
        '2709062',
        '4705272',
        'Sheetz - 315',
        '5717',
        '6336',
        '6402',
        'Test Site - TLS 300 - DNR',
        'Sheetz - 413',
        '4706883',
        "1850-11 - Richmond Int'l Airport - RAC Hertz",
        '4705627',
        '4818',
        '2723721',
        'Enmarket - 884',
        '4586',
        'Sheetz - 414',
        '2723825',
        '4705701',
        'CEFCO #485',
        'WI0069',
        'Enmarket - 863',
        '7156',
        '2741795',
        '4706746',
        '2723732',
        '4706743',
        'Sun Gas',
        '6343',
        '4985',
        '1750-12 - Elmira-Corning Regional Airport - RAC Hertz',
        'Blanding Exxon',
        '4852',
        '8268',
        'Sheetz - 630',
        '6245',
        '7021',
        'FedEx Ground # 722',
        '4705253',
        '2723728',
        '2741790',
        '6083',
        '4705635',
        '8266',
        '8112',
        '4705322',
        '6502',
        'PA0234',
        'Sheetz - 205',
        '3043',
        'FedEx Ground # 373',
        '5791',
        'IL0141'
      ],
      route_plan: [
        '4705251',
        'Sheetz - 489',
        '5378',
        '7347',
        '6459',
        '6354',
        'Enmarket - 896',
        'GIANT #198',
        'Sheetz - 258',
        '6929',
        'Sheetz - 286',
        '4706748',
        'Enmarket - 858',
        'GIANT #122',
        '4705721',
        '6036',
        'WI0071',
        '4470',
        'PA0433',
        'WI0005',
        "1740-11 - Portland Int'l Jetport - RAC",
        'Sheetz - 460',
        '8280',
        '3885',
        '6333',
        '4706865',
        '4706744',
        '4705644',
        '4528',
        '5782',
        '4705689',
        '4706830',
        'Sheetz - 436',
        '4784',
        '6578',
        'GIANT #357',
        '4706804',
        '3472',
        'Enmarket - 898',
        '2834',
        '4705501',
        '2723824',
        '2723726',
        '1868',
        'Enmarket - 860',
        '3880',
        'Sheetz - 595',
        '2741776',
        '4706725',
        'Sheetz - 350',
        '8286',
        '2723823',
        '4705631',
        'Sheetz - 243',
        'GIANT #117',
        '4705621',
        'Sheetz - 201',
        '4706747',
        '4852',
        'PA0053',
        'Sheetz - 410',
        'GIANT #355',
        'WI0054',
        'Sheetz - 366',
        '2723729',
        'WI0075',
        '5291',
        '4706727',
        '4879',
        '6085',
        '4705641',
        '8153',
        'Circle K Normandy',
        '4705623',
        '4705567',
        'WI0064',
        'Sheetz - 195',
        '4705685',
        'GIANT #353',
        '4705658',
        '4705278',
        'WI0056',
        'IL0074',
        '4705643',
        'Sheetz - 484',
        'Sheetz - 270',
        'Sheetz - 579',
        '5143',
        '6403',
        '4705443',
        '1531',
        '4705611',
        "1476-11 - Savannah-Hilton Head Int'l Airport - RAC Hertz",
        'GIANT #365',
        '6415',
        '8151',
        'Sheetz - 426',
        '6781',
        '4705724',
        '4671',
        '7014',
        '8296',
        'Sheetz - 414',
        '2741775',
        'GIANT #351',
        '6246',
        '6037',
        'CEFCO #487',
        'Enmarket - 861',
        '2723728',
        '4705642',
        'Enmarket - 897',
        '4705633',
        '2741797',
        '4705632',
        '6401',
        '2723730',
        'Sheetz - 356',
        '2709062',
        '4705272',
        'Sheetz - 315',
        '5717',
        '6336',
        '6402',
        'Sheetz - 413',
        '4706883',
        "1850-11 - Richmond Int'l Airport - RAC Hertz",
        '4705627',
        '2723721',
        'Enmarket - 899',
        'Enmarket - 884',
        '2723825',
        '4705701',
        'CEFCO #485',
        'WI0069',
        'Enmarket - 863',
        '7156',
        '6725',
        '2741795',
        '4706746',
        '2723732',
        '4706743',
        '6343',
        '4985',
        '1480-11 - Chattanooga Metropolitan Airport (Lovell Field) - RAC Hertz',
        'GIANT #361',
        '6339',
        '8268',
        'Sheetz - 630',
        '6245',
        '7021',
        '7030',
        "1740-21 - Bangor Int'l Airport - RAC Hertz",
        'FedEx Ground # 722',
        '4705253',
        'Sheetz - 518',
        '2741790',
        '6083',
        '8266',
        '8112',
        '6502',
        'PA0234',
        'Sheetz - 205',
        '3043',
        'PA0248',
        '5791',
        'IL0141',
        'GIANT #364',
        '4706806'
      ],
      permit: [
        '6245',
        '7021',
        '7030',
        "1740-21 - Bangor Int'l Airport - RAC Hertz",
        'FedEx Ground # 722',
        '4705253',
        'Sheetz - 518',
        '2741790',
        '6083',
        '8266',
        '8112',
        '6502',
        'PA0234',
        'Sheetz - 205',
        '3043',
        'PA0248',
        '5791',
        'IL0141',
        'GIANT #364',
        '4706806'
      ]
    }
  }
};

const trends = {
  data: {
    index: ['5f25809d63836556cd66d504', 'Total'],
    data: [
      [1, 1],
      [1, 1]
    ],
    columns: [
      ['NUM ISSUES', '2020-08'],
      ['NUM ISSUES', 'Total']
    ]
  }
};

export {
  notificationData,
  notificationConfig,
  tapesData,
  commandsData,
  alarmsData,
  calendar,
  trends
};
