import PropTypes from 'prop-types';

const Notification = ({ title, text }) => {
  return (
    <div className="content">
      <div className="title">{title}</div>
      <div className="text">{text}</div>
    </div>
  );
};

Notification.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element]).isRequired
};

export default Notification;
