import MockAdapter from 'axios-mock-adapter';
import { tlsSites } from './demo_data';

const mockAxios = ax => {
  if (process?.env?.REACT_APP_IS_LOCAL) {
    // eslint-disable-next-line no-console
    console.log('THIS IS LOCAL BUILD!!!!');
    // eslint-disable-next-line no-console
    console.log('SOME REQUESTS WILL BE MOCKED!');
    const mock = new MockAdapter(ax, {
      onNoMatch: 'passthrough',
      delayResponse: 1000
    });

    mock.onGet('/api/v3/atg/connection_center/sites?name=tls').reply(200, tlsSites);
    // eslint-disable-next-line no-console
    console.log(mock);
  }
};

export default mockAxios;
