import propTypes from 'prop-types';
import {
  ATG_CONNECTION_FAIL_STATUS,
  ATG_CONNECTION_UNKNOWN_STATUS,
  ATG_CONNECTION_SUCCESS_STATUS
} from './const';

const ConnectionStatus = ({ status }) => {
  const statusStyleMapping = {
    [ATG_CONNECTION_SUCCESS_STATUS]: 'green',
    [ATG_CONNECTION_FAIL_STATUS]: 'red',
    [ATG_CONNECTION_UNKNOWN_STATUS]: 'yellow'
  };

  const baseOvalStyle = 'wo-badge-status-oval wrapped';
  const ovalStyle = `${baseOvalStyle} ${statusStyleMapping[status]}`;

  return (
    <span className="wo-badge-wrapper-oval">
      <span className={ovalStyle} />
    </span>
  );
};

ConnectionStatus.propTypes = {
  status: propTypes.string.isRequired
};

export default ConnectionStatus;
