module.exports = {
  api: {
    api_url: process.env.REACT_APP_API_ENDPOINT || '',
    api_version: '/api/version',
    auth: {
      csrf_login: '/auth/csrf-login',
      loginV2: '/api/v2/login',
      me: '/api/v2/users/me',
      login: '/auth/login',
      sso_verify: (ssoId, token) => `/auth/sso/${ssoId}/${token}`,
      logout: '/api/v2/logout',
      refresh_token: '/api/v2/refresh-token',
      auth_test: '/auth/test',
      csrf_reset_password: '/auth/csrf-reset',
      reset_password: token => `/api/v2/auth/forgot_password/${token}`,
      forgot_password: '/api/v2/auth/forgot_password',
      invite: '/api/v2/qr-code-link/invite',
      send_code: '/api/v2/login/submit-code',
      resend_code: '/api/v2/login/resend-code'
    },
    atg: {
      generate_report: id => `/api/atg/responses/${id}/generate_report`,
      release_detection: siteId => `/api/v2/atg/site/${siteId}/release-detection`,
      sites: '/api/v3/atg/sites',
      sites_command_center: '/api/v3/atg/command_center/sites',
      sites_connection_center: '/api/v3/atg/connection_center/sites',
      diagnose_connection: '/api/v2/atg/connection_center/diagnose',
      diagnose_result: sessionId =>
        `/api/v2/atg/connection_center/diagnostic_session/responses/${sessionId}`,
      sites_release_detection: '/api/v3/atg/release_detection/sites',
      commands: id => `/api/v2/atg/sites/${id}/responses`,
      facility_setup_command: (siteId, commandId) =>
        `/api/v2/atg/sites/${siteId}/setup-commands/${commandId}`,
      setup_command_response: responseId => `/api/v2/atg/response/${responseId}/status`,
      facility_setup_command_populate: (siteId, commandId) =>
        `/api/v2/atg/sites/${siteId}/setup-commands/${commandId}/populate`,
      facility_setup_command_population_data: (siteId, commandId) =>
        `/api/v2/atg/sites/${siteId}/setup-commands/${commandId}/populated-data`,
      facility_setup_command_execute: (siteId, commandId) =>
        `/api/v2/atg/sites/${siteId}/setup-commands/${commandId}/execute`,
      setup_center_sites: '/api/v2/atg/sites/setup_center',
      setup_commands_categories: siteId => `/api/v2/atg/sites/${siteId}/setup-commands/categories`,
      setup_commands_by_category: (siteId, category) =>
        // eslint-disable-next-line
        `/api/v2/atg/sites/${siteId}/setup-commands${category ? '?category=' + category : ''}`,
      download_report: id => `/api/v2/atg/responses/${id}/download_pdf`,
      display_pdf: responseId => `/api/v2/atg/responses/${responseId}/display_pdf`,
      execute_commands: '/api/v2/atg/execute-commands',
      available_commands: '/api/v2/atg/available-commands',
      check_report_availability: id => `/api/atg/responses/${id}/check_atg_report`,
      rerun_command: responseId => `/api/v2/atg/responses/${responseId}/rerun`,
      alarms: '/api/v3/atg/alarms',
      functional_command_start: siteId => `/api/v2/atg/sites/${siteId}/function/execute`,
      functional_command_status: (siteId, commandId) =>
        `/api/v2/atg/sites/${siteId}/function/${commandId}/info`,
      response: id => `/api/v2/atg/responses/${id}`
    },
    notifications: {
      readNotification: id => `/api/v2/notifications/${id}/read`,
      notifications: '/api/v2/notifications',
      configs: '/api/v2/notifications/configs',
      saveConfig: '/api/v2/notifications/configs/save_config',
      mark_all: '/api/v2/notifications/mark_all_as_read/'
    },
    filesV3: {
      root: '/api/v3/files',
      // move: `/api/v3/files/move`,
      searchFolder: id => `/api/v3/files/${id}/search`,
      search: '/api/v3/files/search',
      // TODO: remove after testing new solution
      // file_path: fileId => `/files/id/${fileId}/`,
      file_path: fileId => `/api/v3/files/${fileId}`,
      share_file: fileId => `/api/v2/files/${fileId}/shares`,
      stop_share: (fileId, shareId) => `/api/v2/files/${fileId}/shares/${shareId}`,
      update_shared: (fileId, shareId) => `/api/v2/files/${fileId}/shares/${shareId}`,
      share_info: id => `/api/v2/files/${id}/shares`,
      shared_file_path: fileId => `/files/share/${fileId}/`,
      shared_sub_file_path: (fileId, subId) => `/files/share/${fileId}/${subId}`,
      download_path: fileId => `/files/download_queue/${fileId || ''}`,
      // download_shared_path: fileId => `/files/download_queue/${fileId}`,
      facility_files: siteId => `/api/files/site/${siteId}/`,
      upload: id => `/api/v3/files/${id}/upload_files`,
      // create_folder: '/files/new_directory',
      create_folder: id => `/api/v3/files/${id}/create_folder`,
      append_download_queue: '/files/append_download_queue',
      pop_download_queue: '/files/pop_download_queue',
      clear_download_queue: '/files/clear_download_queue',
      photo_folder: siteId => `/api/files/site/${siteId}/Photos`,
      display_file: fileId => `/files/id/${fileId}/?display=true`,
      set_ariel: (siteId, fileId) => `/api/files/ariel_photo/${siteId}/${fileId}/`,
      set_sitemap: (siteId, fileId) => `/api/files/sitemap_photo/${siteId}/${fileId}/`,
      get_url: fileId => `/files/external_link/${fileId}`,
      get_shared_url: (uuid, id) => `/files/share/${uuid}/${id}?action=external-link`
    },
    inventory: {
      select_site: '/api/v1/inventory_management/allowed_sites/',
      rerun: id => `/api/v1/inventory_management/tanks/${id}/rerun`,
      tanks: id => `/api/v1/inventory_management/tanks/${id}`
    },
    files: {
      root: '/api/v2/files',
      move: '/files/move',
      search: '/files/search',
      file_path: fileId => `/api/v2/files/${fileId}`,
      share_file: fileId => `/api/v2/files/${fileId}/shares`,
      stop_share: (fileId, shareId) => `/api/v2/files/${fileId}/shares/${shareId}`,
      update_shared: (fileId, shareId) => `/api/v2/files/${fileId}/shares/${shareId}`,
      share_info: id => `/api/v2/files/${id}/shares`,
      shared_file_path: fileId => `/files/share/${fileId}/`,
      shared_sub_file_path: (fileId, subId) => `/files/share/${fileId}/${subId}`,
      download_path: fileId => `/files/download_queue/${fileId}`,
      // download_shared_path: fileId => `/files/download_queue/${fileId}`,
      facility_files: siteId => `/api/files/site/${siteId}/`,
      upload: '/files/upload',
      create_folder: '/files/new_directory',
      append_download_queue: '/files/append_download_queue',
      pop_download_queue: '/files/pop_download_queue',
      clear_download_queue: '/files/clear_download_queue',
      photo_folder: siteId => `/api/files/site/${siteId}/Photos`,
      display_file: fileId => `/files/id/${fileId}/?display=true`,
      set_ariel: (siteId, fileId) => `/api/files/ariel_photo/${siteId}/${fileId}/`,
      set_sitemap: (siteId, fileId) => `/api/files/sitemap_photo/${siteId}/${fileId}/`,
      get_url: fileId => `/files/external_link/${fileId}`,
      add_shared_file: uuid => `/api/v2/files/qr-sharing/${uuid}`
    },
    workorders: {
      workorders: '/api/v2/workorders',
      line_items: id => `/api/v2/workorder/line_items/${id}`,
      details: id => `/api/v2/workorder/detail_page/wo_details/${id}`,
      wo_line_items: id => `/api/v2/workorder/detail_page/line_items/${id}`,
      issue_group_items: id => `/api/v2/workorder/detail_page/issues_group_items/${id}`,
      assign_to_users: id => `/api/v2/issues_group/users/assigned_to/${id}`,
      dispatch_to_users: id => `/api/v2/issues_group/users/dispatch_to/${id}`,
      logs: id => `/api/v2/workorder/detail_page/change_logs/${id}`,
      create_group: '/api/v2/issues_group',
      edit_group: id => `/api/v2/issues_group/${id}`,
      delete_group: id => `/api/v2/issues_group/delete/${id}`,
      reopen: id => `/api/v2/workorder/detail_page/reopen_wo/${id}`,
      issuesTypes: '/api/v2/workorder/line_item_types',
      issuesGroups: id => `/api/v2/workorder/detail_page/wo_details/${id}/issue_groups`
    },
    issues: {
      create_issue: siteId => `/api/issues/site/${siteId}/create`,
      site_issues: siteId => `/api/issues/site/${siteId}`,
      add_comment: issue => `/issues/${issue}/submit_comment`,
      facilityIssues: siteId => `/api/v2/issues/site/${siteId}`,
      issues_detail: id => `/api/v2/vi_issues/${id}/details`,
      issues_set_due_date: id => `/api/v2/vi_issues/set_due_date/${id}`,
      issues_change_status: id => `/api/v2/vi_issues/change_status/${id}`,
      issues_change_user: id => `/api/v2/vi_issues/assign_user/${id}`,
      issues_change_assign: (id, type) => `/api/v2/vi_issues/assigned_to/${type}/${id}`,
      issues_occurrences: id => `/api/v2/vi_issues/${id}/previous_occurrences`,
      issue_change_log: id => `/api/v2/vi_issues/${id}/change_logs`,
      issue_add_comment: id => `/api/v2/vi_issues/create_comment/${id}`,
      issue_comments: id => `/api/v2/vi_issues/${id}/comments`,
      issue_related_files: id => `/api/v2/vi_issues/${id}/related_files`,
      assign_to_users: id => `/api/v2/vi_issues/assigned_to/assignment/${id}`,
      dispatch_to_users: id => `/api/v2/vi_issues/assigned_to/dispatch/${id}`,
      mentions: id => `/api/v2/vi_issues/users/mentions/${id}`,
      issue_atg_history: id => `/api/v3/atg/log_history/${id}`,
      previous_occurrences: id => `/api/v3/vi_issues/${id}/previous_occurrences`
    },
    groups: {
      detail: id => `/api/v2/issues_group/${id}/details`,
      set_due_date: id => `/api/v2/issues_group/set_due_date/${id}`,
      assign_user: id => `/api/v2/issues_group/assign_user/${id}`,
      assign_list: id => `/api/v2/issues_group/users/assigned_to/${id}/`,
      dispatch_list: id => `/api/v2/issues_group/users/dispatch_to/${id}/`,
      change_status: id => `/api/v2/issues_group/change_status/${id}`,
      line_items: id => `/api/v2/issues_group/${id}/line_items`,
      change_logs: id => `/api/v2/issues_group/${id}/change_logs`,
      add_comment: id => `/api/v2/issues_group/create_comment/${id}`,
      comments: id => `/api/v2/issues_group/${id}/comments`,
      related_files: id => `/api/v2/issues_group/${id}/related_files`,
      add_related_file: id => `/api/v2/issues_group/${id}/related_files`,
      mentions: id => `/api/v2/issues_group/users/mentions/${id}`,
      complete: `/api/v2/issues_group/submit`
    },
    inspections: {
      photo: id => `/api/photo/${id}`
    },
    reports: {
      display_pdf: fileID => `/api/v2/reports/${fileID}/display_pdf`,
      download_pdf: fileID => `/api/v2/reports/${fileID}/download_pdf`,
      site_reports: siteId => `/api/v2/reports/site/${siteId}`,
      cp_report: '/api/cp_report',
      recap_report: '/api/recap_report',
      hot_topic_report: (year, month) => `/api/ht_report/year/${year}/month/${month}`,
      remedy_report_monthly: (year, month, client) =>
        `/api/remedy_report/year/${year}/month/${month}/client/${client}`,
      remedy_report_weekly: (year, week, client) =>
        `/api/remedy_report/year/${year}/week/${week}/client/${client}`,
      widget_report: (type, view) => `/api/widget/${type}/${view}`,
      ht_report: clientId => `/api/v2/ht_report/${clientId}`,
      remedy_report: `/api/v2/remedy_report`,
      walmart_report: `/api/v2/walmart_scorecard`,
      daily_report: `/api/v2/reports/daily_overview`
    }
  }
};
