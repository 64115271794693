import PropTypes from 'prop-types';
import { Spinner } from '../../helpers/Spiners';
import { ReactComponent as CheckIcon } from '../../assets/images/icon-check.svg';
import { ReactComponent as CrossIcon } from '../../assets/images/cross.svg';

const smallStyles = {
  border: '2px solid #616674',
  borderRightColor: 'transparent',
  width: '15px',
  height: '15px'
};

const Status = ({ status }) => {
  switch (status) {
    case true:
      return <CheckIcon />;
    case false:
      return <CrossIcon />;
    default:
      return <Spinner styles={smallStyles} />;
  }
};

Status.propTypes = {
  status: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])])
};
Status.defaultProps = {
  status: null
};

export default Status;
