import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { PageContext } from '../layout/pageContext';
import configAPI from '../_config/config';
import axios from '../_actions/axiosInstance';
import Notification from '../components/Notification';
// import { notificationData } from '../helpers/demo_data';

let draftNotifications = [];

const usePageContext = () => {
  const [state, setState] = useContext(PageContext);

  function getNotifications() {
    // let alerts = [];
    // let comments = [];
    // try {
    //   const res = await axios.get(configAPI.api.api_url + configAPI.api.getNotifications);
    //   alerts = res.data.filter(n => !n.message.includes('commented'));
    //   comments = res.data.filter(n => n.message.includes('commented'));
    // } catch (e) {
    //   console.log(e)
    // }
    // setState({
    //   alerts,
    //   comments,
    //   loading: false,
    //   loadingConfig: false
    // });
    const { notificationSocket, loading } = state;
    if (notificationSocket.connected || loading) return;
    // setState({ ...state, loading: true, loadingConfig: true });
    // let draftNotifications = [];
    // let draftConfig = {};
    notificationSocket.connect();
    // notificationSocket.on('config', data => {
    //   if (data) {
    //     if (!draftConfig.action_types) {
    //       draftConfig = data;
    //       setState({
    //         ...state,
    //         alerts: draftNotifications.filter(n => !n.message.includes('commented')),
    //         comments: draftNotifications.filter(n => n.message.includes('commented')),
    //         loading: false,
    //         loadingConfig: false,
    //         // alerts: notificationData.filter(n => !n.message.includes('commented')),
    //         // comments: notificationData.filter(n => n.message.includes('commented')),
    //         config: draftConfig
    //       });
    //     }
    //   }
    // });

    notificationSocket.on('notifications', data => {
      if (data) {
        draftNotifications = [data, ...draftNotifications]
          .filter((v, i, a) => a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i)
          .sort((a, b) => (a.created_on > b.created_on ? -1 : 1));
        setState({
          ...state,
          alerts: draftNotifications.filter(n => n.action_type !== 'tagged'),
          comments: draftNotifications.filter(n => n.action_type === 'tagged')
        });
      }
    });

    // notificationSocket.on('bootstrap-notifications', data => {
    //   if (data) {
    //     draftNotifications = [...draftNotifications, ...data];
    //   }
    // });

    // notificationSocket.on('bootstrap-notifications-flush', data => {
    //   console.log('bootstrap-notifications-flush', data);
    // });

    // notificationSocket.on('bootstrap-notifications-etb', data => {
    //   console.log('bootstrap-notifications-etb', data);
    // });

    // notificationSocket.on('bootstrap-notifications-etx', data => {
    //   if (data) {
    //     setState({
    //       ...state,
    //       loading: false,
    //       loadingConfig: false,
    //       alerts: draftNotifications.filter(n => !n.message.includes('commented')),
    //       comments: draftNotifications.filter(n => n.message.includes('commented')),
    //       config: draftConfig
    //     });
    //     // draftNotifications = [];
    //   }
    // });

    // notificationSocket.on('bootstrap-notifications-soh', data => {
    //   if (data) {
    //     console.log('bootstrap-notifications-soh', data);
    //   }
    // });

    notificationSocket.on('disconnect', reason => {
      const reasonList = ['io server disconnect', 'io client disconnect', 'ping timeout'];
      if (reasonList.includes(reason)) {
        notificationSocket.io.engine.close();
      }
    });
  }

  async function getItems() {
    let alerts = [];
    let comments = [];
    try {
      const res = await axios.get(
        configAPI.api.api_url + configAPI.api.notifications.notifications
      );
      draftNotifications = res.data.data;
      alerts = res.data.data.filter(n => n.action_type !== 'tagged');
      comments = res.data.data.filter(n => n.action_type === 'tagged');
    } catch (e) {
      console.error(e);
    }
    const newState = { ...state, firstRender: false, alerts, comments };
    setState(newState);
  }

  async function getConfig() {
    let config;
    try {
      const res = await axios.get(configAPI.api.api_url + configAPI.api.notifications.configs);
      config = res.data;
    } catch (e) {
      console.error(e);
    }
    const newState = {
      ...state,
      config,
      alerts: draftNotifications.filter(n => n.action_type !== 'tagged'),
      comments: draftNotifications.filter(n => n.action_type === 'tagged')
    };
    setState(newState);
  }

  async function markAsRead(nId, nType) {
    // const { notificationSocket } = state;
    const arr = state[nType];
    try {
      await axios.get(configAPI.api.api_url + configAPI.api.notifications.readNotification(nId));
      arr.forEach(e => {
        if (e.id === nId) {
          e.status = 'read';
        }
        return e;
      });
      const nextState = { ...state };
      nextState[nType] = arr;
      setState(nextState);
    } catch (e) {
      console.error(e);
      toast.info(<Notification title="Oops.." text={e.response.data.errors.join('<br />')} />);
    }
    // notificationSocket.emit('message-read', nId);
  }

  function changeConfig(configItem) {
    const { config, timer } = state;
    const cIndex = config.action_types.findIndex(c => c.action_type === configItem.action_type);
    config.action_types[cIndex] = configItem;
    if (timer != null) {
      window.clearTimeout(timer);
    }
    setState({
      ...state,
      config,
      timer: window.setTimeout(submitConfig, 5000)
    });
  }

  async function submitConfig() {
    const { config } = state;
    try {
      await axios.post(configAPI.api.api_url + configAPI.api.notifications.saveConfig, config);
    } catch (e) {
      console.error(e);
      toast.info(<Notification title="Oops.." text={e.response.data.errors.join('<br />')} />);
    }
  }

  return {
    ...state,
    getNotifications,
    markAsRead,
    changeConfig,
    submitConfig,
    getItems,
    getConfig
  };
};

export default usePageContext;
