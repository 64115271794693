import PropTypes from 'prop-types';
import Page from '../../components/Page';
import { ReactComponent as Octopus } from '../../assets/images/octopus.svg';

const CommingSoon = ({ history }) => {
  const { title } = history.location.state;
  return (
    <Page documentName={`proteän - ${title}`} pageName={title} path={title}>
      <div className="d-flex align-items-center flex-column comming_soon">
        <h4>Coming soon...</h4>
        <div className="disclaimer mx-4 mt-2 mb-3">
          The mobile version of this page is under construction
        </div>
        <button
          onClick={() => {
            history.goBack();
          }}
          className="btn-retun-back mb-4"
          type="button"
        >
          Back to previous page
        </button>
        <Octopus />
      </div>
    </Page>
  );
};

CommingSoon.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    location: PropTypes.shape({
      state: PropTypes.shape({
        title: PropTypes.string
      })
    })
  }).isRequired
};

export default CommingSoon;
