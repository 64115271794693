import { createStore, createHook } from 'react-sweet-state';

const ATGStore = createStore({
  initialState: {
    items: [],
    site: null,
    isDiagnosticModalOpen: false,
    shouldUpdate: false
  },
  actions: {
    setSite: site => ({ setState }) => {
      setState({ site });
    },
    setInitSiteState: () => ({ setState, getState }) => {
      const { site } = getState();
      setState({
        site: {
          ...site,
          success_ping: null,
          success_command: null,
          success_connection: null
        }
      });
    },
    openDiagnosticModal: () => ({ setState }) => {
      setState({ isDiagnosticModalOpen: true });
    },
    setShouldUpdated: shouldBeUpdated => ({ setState }) => {
      setState({ shouldUpdate: shouldBeUpdated });
    },
    toggleDiagnosticModalOpen: () => ({ setState, getState }) => {
      const { isDiagnosticModalOpen } = getState();
      setState({ isDiagnosticModalOpen: !isDiagnosticModalOpen });
    }
  },

  name: 'ATGStore'
});

const useATGStore = createHook(ATGStore);

export default useATGStore;
