import Modal from 'react-responsive-modal';
import { useState } from 'react';
import useATGStore from './store';
import { CONNECTION_MODAL_ADDRESS_STEP, CONNECTION_MODAL_DIAGNOSE_STEP } from './const';
import Diagnose from './diagnose';
import InfoStep from './infoStep';

const styles = {
  overlay: { backgroundColor: '#24272a4d', padding: '12px', zIndex: '9999' },
  modal: {
    padding: 0,
    minWidth: '100%',
    maxWidth: '100%',
    maxHeight: '514px',
    borderRadius: '4px',
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)'
  },
  closeIcon: {
    fill: '#616674'
  }
};

const ConnectionModal = () => {
  const [{ isDiagnosticModalOpen }, { toggleDiagnosticModalOpen }] = useATGStore();
  const [step, goToStep] = useState(CONNECTION_MODAL_ADDRESS_STEP);

  const onClose = () => {
    toggleDiagnosticModalOpen();
  };

  const onSubmit = () => {
    goToStep(CONNECTION_MODAL_DIAGNOSE_STEP);
  };

  return (
    <Modal
      focusTrapped={false}
      open={isDiagnosticModalOpen}
      onClose={onClose}
      styles={styles}
      classNames={{ modal: 'share-modal', closeButton: 'close-btn' }}
      center
    >
      <div className="card mb-0 flex-fill base-modal run-modal">
        <div className="card-header header-elements-inline pb-1">
          <h4 className="card-title">Diagnose ATG Connection</h4>
        </div>
        <div className="card-body">
          {step === CONNECTION_MODAL_ADDRESS_STEP && (
            <InfoStep onCLose={onClose} onSubmit={onSubmit} />
          )}
          {step === CONNECTION_MODAL_DIAGNOSE_STEP && (
            <Diagnose toPrevStep={() => goToStep(CONNECTION_MODAL_ADDRESS_STEP)} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConnectionModal;
