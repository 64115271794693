import { useEffect, useRef } from 'react';
import Axios from 'axios';
import useATGStore from './store';
import Page from '../../components/Page';
import SelectSite from '../../components/atg/SelectSite';
import config from '../../_config/config';
import ConnectionItem from './connectionItem';
import Diagnostic from './diagnostic';
import axios from '../../_actions/axiosInstance';

const ConnectionHeader = () => (
  <div className="product-header">
    <div className="value hide">Status</div>
    <div className="value">Client</div>
    <div className="value">Last connection</div>
    <div className="value hide">Action</div>
  </div>
);

const ConnectionCenter = () => {
  const [{ site, shouldUpdate }, { setSite, setShouldUpdated }] = useATGStore();
  const sourceRef = useRef();
  useEffect(() => {
    if (shouldUpdate && site) {
      const loadOptions = async val => {
        if (sourceRef?.current?.cancel) {
          sourceRef.current.cancel('refresh');
        }
        sourceRef.current = Axios.CancelToken.source();
        try {
          const res = await axios.get(
            `${config.api.api_url}${
              config.api.atg.sites_connection_center
            }?name=${encodeURIComponent(val)}`,
            {
              cancelToken: sourceRef.current.token
            }
          );
          const requestedSite = res.data.find(item => item.site_id === site.site_id);
          if (!requestedSite) {
            return;
          }
          setSite(requestedSite);
          setShouldUpdated(false);
          return;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      };
      loadOptions(site.atg_site);
    }
  }, [shouldUpdate]);
  return (
    <Page
      documentName="proteän - Connection Center"
      path="Remote monitoring"
      pageName="Remote monitoring"
    >
      <div className="protean-atg">
        <div className="atg-page-header">
          <div className="title">ATG Connection Center</div>
          <div className="sub-title">ATG Management</div>
        </div>
        <div className="page-description">
          The mobile version for this page works different from desktop. Please select a facility to
          start.
        </div>
        <SelectSite
          selectSite={setSite}
          site={site}
          url={`${config.api.api_url}${config.api.atg.sites_connection_center}`}
        />
        <Diagnostic />
        <ConnectionHeader />
        <ConnectionItem item={site} />
      </div>
    </Page>
  );
};

export default ConnectionCenter;
