import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Navbar from './Navbar';
import PageHeader from './PageHeader';
import SideBar from './SideBar';
import { getUserData } from '../_actions';
import usePageContext from '../hooks/usePageContext';

const Page = ({ documentName, path, pageName, extraPath, children }, history) => {
  document.title = documentName;
  const user = getUserData();
  const { getItems, getConfig, firstRender } = usePageContext();

  const checkWidth = () => {
    if (window.innerWidth > 768) {
      document.body.classList.add('sidebar-xs');
      document.getElementById('content-wrapper').classList.remove('pl-3');
    } else {
      document.getElementById('content-wrapper').classList.add('pl-3');
      document.body.classList.remove('sidebar-xs');
    }
  };

  // useEffect(() => {
  //   getNotifications();
  // }, [getNotifications]);
  useEffect(() => {
    if (firstRender) {
      getItems();
      getConfig();
    }
  }, []);

  useEffect(() => {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
    checkWidth();
    window.addEventListener('resize', checkWidth);
    return () => {
      window.removeEventListener('resize', checkWidth);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Navbar />
      <PageHeader path={path} pageName={pageName} extraPath={extraPath} />
      <div className="page-content">
        <SideBar user={user} />
        <div className="content-wrapper pr-3" id="content-wrapper">
          <div className="content p-0">{children}</div>
        </div>
      </div>
    </>
  );
};

Page.propTypes = {
  documentName: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  children: PropTypes.node,
  extraPath: PropTypes.node
};

Page.defaultProps = {
  extraPath: null,
  children: null
};

export default React.memo(Page);
