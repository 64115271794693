import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getFirstEntry } from '../helpers/utils';
import { ReactComponent as HomeIcon } from '../assets/images/home.svg';

const HeaderLink = ({ href, iClass, text }) => {
  const linkClasses = `${iClass} text-primary`;
  return (
    <Link to={href} className="btn btn-link btn-float text-default">
      <i className={linkClasses} />
      <span>{text}</span>
    </Link>
  );
};

HeaderLink.propTypes = {
  href: PropTypes.string.isRequired,
  iClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

const PageHeader = ({ path, pageName, extraPath }) => {
  // const user = getUserData();
  const click = () => {
    document.getElementById('support-block').classList.toggle('d-none');
  };

  return (
    <div className="page-header page-header-light">
      <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
        <div className="d-flex">
          <div className="breadcrumb">
            <Link to={getFirstEntry()} className="breadcrumb-item">
              <HomeIcon
                className="d-inline-block"
                style={{ marginRight: '8px', marginBottom: '4px' }}
              />
              Home
            </Link>
            {extraPath}
            <span className="breadcrumb-item active">{pageName}</span>
          </div>
          <button
            className="reset-button header-elements-toggle text-default d-md-none"
            type="button"
            onClick={click}
          >
            <i className="icon-more" />
          </button>
        </div>
        <div className="header-elements d-none" id="support-block">
          <div className="breadcrumb justify-content-center">
            <a
              href="mailto:support@7g-enviro.com "
              className="breadcrumb-elements-item d-inline-flex mr-1 align-items-center"
            >
              <i className="icon-comment-discussion mr-2" />
              Support
            </a>
          </div>
        </div>
      </div>
      <div className="page-header-content header-elements-md-inline">
        <div className="page-title d-flex justify-content-between w-100">
          <div className="d-flex col-md-8 px-0">
            <h4>
              <span className="protean-page-title">
                {/* &nbsp;-&nbsp; */}
                {path}
              </span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  pageName: PropTypes.string.isRequired,
  extraPath: PropTypes.node,
  path: PropTypes.string.isRequired
};

PageHeader.defaultProps = {
  extraPath: null
};

const PageHeaderWrapper = React.memo(PageHeader);
export default PageHeaderWrapper;
