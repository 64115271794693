// import axios from 'axios';
import axios from './axiosInstance';
import config from '../_config/config';
import {
  storeItem,
  removeItemFromStorage,
  setTokens,
  getUser,
  setUser,
  clearLocalStorage
} from '../helpers/localStorage';

// axios.defaults.withCredentials = true;

export const logout = async () => {
  try {
    await axios.delete(config.api.api_url + config.api.auth.logout);
  } catch (error) {
    console.error(error);
  } finally {
    clearLocalStorage();
    removeItemFromStorage('user');
  }
};

export const fetchUserData = () => {
  let user = getUserData();
  if (user.logged_in) return true;
  try {
    axios.get(config.api.api_url + config.api.auth.auth_test).then(resp => {
      storeItem('user', resp.data);
    });
  } catch (error) {
    if (error.response && error.response.data) {
      storeItem('user', error.response.data);
    }
  }
  user = getUserData();
  return user.logged_in;
};

export const getUserData = () => {
  let user = getUser();
  if (!user) {
    user = { logged_in: false };
    storeItem('user', user);
  }
  return user;
};

export const resetPassword = async (token, data) => {
  let result;
  try {
    await axios.post(config.api.api_url + config.api.auth.reset_password(token), data);
    result = {
      msg: 'success',
      success: true
    };
  } catch (e) {
    result = {
      msg: 'Server Error',
      success: false
    };
    if (e.response && e.response.data.errors) {
      result.msg = e.response.data.errors;
    }
  }
  return result;
};

export const forgotPassword = async (email, qr) => {
  let result;
  try {
    await axios.post(
      `${config.api.api_url}${config.api.auth.forgot_password}${qr ? `?qr=${qr}` : ''}`,
      { email }
    );
    result = {
      msg: '',
      success: true
    };
  } catch (e) {
    console.log(e.response);
    result = {
      msg: 'Server error',
      success: false
    };
    if (e.response && e.response.data.errors) {
      result.msg = e.response.data.errors;
    }
  }
  return result;
};

// V2 login

export const getMe = async () => {
  let result = false;
  try {
    const response = await axios.get(config.api.api_url + config.api.auth.me);
    setUser(response.data);
    result = true;
  } catch (e) {
    clearLocalStorage();
    result = false;
  }
  return result;
};

export const reSendCode = async (email, password) => {
  let res = {};
  try {
    await axios.post(config.api.api_url + config.api.auth.resend_code, {
      email,
      password
    });
    res.success = true;
  } catch (error) {
    console.dir(error);
    res = error.response.data;
    res.success = false;
  }
  return res;
};

export const sendCode = async (email, password, code) => {
  const res = {};
  try {
    const response = await axios.post(config.api.api_url + config.api.auth.send_code, {
      email,
      password,
      code
    });
    setTokens(response.data);
    res.success = true;
  } catch (error) {
    console.dir(error);
    res.data = error.response.data;
    res.success = false;
  }
  return res;
};

export const login = async (email, password) => {
  let res = {};
  try {
    const response = await axios.post(config.api.api_url + config.api.auth.loginV2, {
      email,
      password
    });
    setTokens(response.data);
    res.use_mfa = response?.data?.use_mfa;
    res.success = true;
  } catch (error) {
    console.dir(error);
    res = error.response.data;
    res.success = false;
  }
  return res;
};

export const logoutV2 = () => {
  clearLocalStorage();
};

export const invite = async data => {
  let result;
  try {
    await axios.post(config.api.api_url + config.api.auth.invite, data);
    result = {
      success: true
    };
  } catch (error) {
    console.dir(error);
    result = error.response.data;
    result.success = false;
  }
  return result;
};

export const getIssueMode = issue => {
  const {
    current_user: { id }
  } = getUserData();
  if (
    issue?.assignment_type === 'dispatch' &&
    issue?.status.toLowerCase() === 'pending acceptance' &&
    issue?.user_id === id
  ) {
    return true;
  }
  return false;
};

export const getMode = group => {
  const {
    current_user: { id }
  } = getUserData();
  if (
    group?.group_type === 'dispatch' &&
    group?.status.toLowerCase() === 'pending acceptance' &&
    group?.user_id === id
  ) {
    return true;
  }
  // if (
  //   group?.group_type === 'assignment' &&
  //   group?.status.toLowerCase() === 'assigned' &&
  //   group?.user_id === id
  // ) {
  //   return true;
  // }
  return false;
};

export const test = () => {};
