import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/Logo-Protean.svg';
import { ReactComponent as SideBarToggleIcon } from '../assets/images/menu-mobile.svg';
import { ReactComponent as UserToggleIcon } from '../assets/images/user-menu-mobile.svg';
import { getFirstEntry, canSeeMobileApp } from '../helpers/utils';
import { getUserData } from '../_actions';

const NavbarMobileButtons = () => {
  const click = () => document.getElementById('navbar-mobile').classList.toggle('show');
  return (
    <button className="navbar-toggler" type="button" onClick={click}>
      <UserToggleIcon className="d-inline-flex" />
    </button>
  );
};

const UserBlock = () => {
  const click = () => document.getElementById('navbar-user-block').classList.toggle('show');
  const user = useMemo(() => getUserData(), []);
  const showApp = useMemo(
    () => canSeeMobileApp(user) && window.location.host === 'm.proteanapp.com',
    [user]
  );

  return (
    <li className="nav-item dropdown dropdown-user">
      <button
        type="button"
        className="navbar-nav-link d-flex align-items-center dropdown-toggle w-100 ml-0"
        onClick={click}
      >
        <span>{user.current_user && user.current_user.name}</span>
      </button>
      <div className="dropdown-menu dropdown-menu-right" id="navbar-user-block">
        {showApp && (
          <a
            className="dropdown-item reset-button"
            href="https://apps.apple.com/ua/app/prote%C3%A4n/id1556719769"
          >
            <i className="fa fa-window-restore" />
            &nbsp;Install proteän
          </a>
        )}
        <Link className="dropdown-item reset-button" to="/logout">
          <i className="icon-switch2" />
          &nbsp;Logout
        </Link>
      </div>
    </li>
  );
};

const SideBarToggler = () => {
  const click = () => document.getElementById('root').classList.toggle('sidebar-mobile-main');
  return (
    <button className="navbar-toggler sidebar-mobile-main-toggle" type="button" onClick={click}>
      <SideBarToggleIcon className="d-inline-flex" />
    </button>
  );
};

const Navbar = () => {
  return (
    <div className="navbar navbar-expand-md navbar-dark">
      <div className="navbar-brand wmin-200 protean-navbar-logo">
        <Link to={getFirstEntry()} className="d-inline-block">
          <img src={logo} alt="logo" />
        </Link>
      </div>

      <div className="d-md-none">
        <NavbarMobileButtons />
        <SideBarToggler />
      </div>

      <div className="navbar-collapse collapse" id="navbar-mobile">
        <ul className="navbar-nav ml-auto">
          <UserBlock />
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
