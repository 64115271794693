import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-right.svg';
import ConnectionStatus from './connectionStatus';

const ConnectionItem = ({ item }) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      {item?.site_id && (
        <>
          <div className={`value-row ${open && 'open'}`}>
            <div className="value hide">
              <ConnectionStatus status={item.status} />
            </div>
            <div className="value client">{item.client_name}</div>
            <div className="value">
              {moment(item.last_connection, 'MM/DD/YYYY H:mm A').format('MM/DD/YYYY hh:mm A')}
            </div>
            <div className="value hide">
              <button
                type="button"
                className={`expand-btn protean-btn ${open && 'rotate-90'}`}
                onClick={() => setOpen(!open)}
              >
                <ArrowRight />
              </button>
            </div>
          </div>
          <div className={`sub-row ${open && 'open'}`}>
            <div className="title">ATG type</div>
            <div className="value">{item.atg_type}</div>
            <div className="title">Address</div>
            <div className="value">{item.address}</div>
            <div className="title">City</div>
            <div className="value">{item.city}</div>
            <div className="title">State</div>
            <div className="value">{item.state}</div>
          </div>
        </>
      )}
    </>
  );
};

ConnectionItem.propTypes = {
  item: PropTypes.instanceOf(Object)
};
ConnectionItem.defaultProps = {
  item: null
};

export default ConnectionItem;
