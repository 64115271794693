import { isBoolean } from 'lodash';
import PropTypes from 'prop-types';
import Status from './status';
import connectionType from './connectionType';

const DiagnoseCommandStep = ({ item, onNextClick, onPrevClick }) => {
  const disabled = !isBoolean(item.success_command);

  return (
    <>
      <div className="info">
        <div className="info-block">
          <div className="w-100">
            <div className="info-value">
              <span className="title-value">IP Address:Port</span>
              <span className="value">{`${item.host}:${item.port}`}</span>
            </div>
          </div>
          <div className="info-block w-100">
            <div className="info-value site">
              <span className="title-value">Site:</span>
              <span className="value">{item.site_name || 'N/a'}</span>
            </div>
            <div className="info-block block-command">
              <div className="info-value command">
                <span className="title-value">Command name:</span>
                <span className="value">System Revision Level Report</span>
              </div>
              <div className="info-value connection">
                <span className="title-value">Status:</span>
                <span className="value">
                  <Status status={item.success_command} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="connection-modal-footer buttons">
        <button type="button" className="protean-btn" onClick={onPrevClick}>
          Back
        </button>
        <button
          type="button"
          className="protean-btn protean-btn-default modal-btn"
          disabled={disabled}
          onClick={onNextClick}
        >
          Next
        </button>
      </div>
    </>
  );
};

DiagnoseCommandStep.propTypes = {
  onPrevClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  item: connectionType.isRequired
};

export default DiagnoseCommandStep;
