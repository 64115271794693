/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AX from 'axios';
import DiagnoseConnectionStep from './diagnoseConnectionStep';
import DiagnoseCommandStep from './diagnoseCommandStep';
import DiagnoseResultStep from './diagnoseResultStep';
import useATGStore from './store';
import axios from '../../_actions/axiosInstance';
import config from '../../_config/config';
import {
  DIAGNOSE_MODAL_COMMAND_STEP,
  DIAGNOSE_MODAL_CONNECTION_STEP,
  DIAGNOSE_MODAL_RESULT_STEP
} from './const';

const TabInner = ({ name, sub }) => (
  <div className="tab-inner">
    <span className="inner-title">{name}</span>
    <span className="inner-sub">{sub}</span>
  </div>
);
TabInner.propTypes = {
  name: PropTypes.string.isRequired,
  sub: PropTypes.string.isRequired
};

const Tabs = ({ tabs, changeTab, extraClass }) => (
  <ul className={`nav nav-tabs nav-tabs-bottom nav-justified facility-tabs ${extraClass}`}>
    {tabs.map(tab => (
      <li className="nav-item" key={tab.id || tab.name}>
        <button
          type="button"
          onClick={() => changeTab(tab.id || tab.name)}
          className={`text-nowrap text-center nav-link facility-tab${
            tab.active ? ' active show' : ''
          } ${tab.disabledMode && 'disabled'}`}
          data-toggle="tab"
        >
          {tab.name}
        </button>
      </li>
    ))}
  </ul>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(Object).isRequired,
  changeTab: PropTypes.func.isRequired,
  extraClass: PropTypes.string
};

Tabs.defaultProps = {
  extraClass: ''
};

const Diagnose = ({ toPrevStep }) => {
  const [{ site: item }, { setSite, setInitSiteState, setShouldUpdated }] = useATGStore();
  const [tabs, setTabs] = useState([
    { id: 1, name: <TabInner name="Step 1" sub="Connection" />, type: 1, active: true },
    { id: 2, name: <TabInner name="Step 2" sub="Commands" />, type: 2, active: false },
    { id: 3, name: <TabInner name="Results" sub=" " />, type: 3, active: false }
  ]);
  const activeTabType = useMemo(() => tabs.find(x => x.active).type, [tabs]);
  const [diagnosticSessionId, setDiagnosticSessionId] = useState('');

  const source = useRef({});
  const delayed = useRef({});
  const mounted = useRef(false);
  const reCallCheck = () => checkRecordsManually();

  const checkRecordsManually = useCallback(async () => {
    if (!diagnosticSessionId) return;
    if (!mounted.current) return;
    let rejected = 0;
    if (source.current.token) {
      source.current.cancel();
    }
    source.current = AX.CancelToken.source();
    try {
      const responses = await axios.get(
        `${config.api.api_url}${config.api.atg.diagnose_result(diagnosticSessionId)}`,
        {
          cancelToken: source.current.token
        }
      );
      responses.data.data.forEach(res => {
        const { success_ping, success_connection, success_command, host, port, site: siteId } = res;
        if (success_ping === null || success_connection === null || success_command === null) {
          rejected += 1;
          return;
        }
        if (!(item.host === host && item.port === port && (!siteId || item.site_id === siteId))) {
          return;
        }

        setSite({
          ...item,
          ...res,
          commandStatus: res.status,
          status: item.status
        });
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('ERROR', e);
      if (!AX.isCancel(e)) {
        reCallCheck();
      }
    }
    // eslint-disable-next-line no-console
    console.log(rejected);
    if (rejected) {
      delayed.current = setTimeout(reCallCheck, 1000);
    }
  }, [reCallCheck]);

  const nextTab = newTab => {
    setTabs(prevTabs => prevTabs.map(tab => ({ ...tab, active: tab.id === newTab })));
  };
  const startDiagnostic = useCallback(() => {
    const requestItem = { host: item.host, port: item.port, site: item.site_id };
    axios.post(config.api.atg.diagnose_connection, { items: [requestItem] }).then(r => {
      const { session_id: sessionId } = r.data;
      if (mounted.current) {
        setDiagnosticSessionId(sessionId);
      }
    });
  }, [item]);

  useEffect(() => {
    if (!diagnosticSessionId) {
      startDiagnostic();
    }
  }, []);

  useEffect(() => {
    if (activeTabType === DIAGNOSE_MODAL_CONNECTION_STEP) {
      checkRecordsManually();
    }
    if (delayed.current && activeTabType > DIAGNOSE_MODAL_CONNECTION_STEP) {
      setDiagnosticSessionId(null);
      clearTimeout(delayed.current);
    }
  }, [diagnosticSessionId]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      setInitSiteState();
      setShouldUpdated(true);
      setDiagnosticSessionId(null);
      clearTimeout(delayed.current);
    };
  }, []);

  return (
    <div className="connection-setup run-diagnose">
      <div className="info">
        <div className="title">
          You start the ATG connection diagnostics for the following site:
        </div>

        <Tabs tabs={tabs} changeTab={() => {}} extraClass="connection" />
        {activeTabType === DIAGNOSE_MODAL_CONNECTION_STEP && (
          <DiagnoseConnectionStep
            item={item}
            onNextClick={() => nextTab(DIAGNOSE_MODAL_COMMAND_STEP)}
            onPrevClick={() => toPrevStep()}
          />
        )}
        {activeTabType === DIAGNOSE_MODAL_COMMAND_STEP && (
          <DiagnoseCommandStep
            item={item}
            onNextClick={() => nextTab(DIAGNOSE_MODAL_RESULT_STEP)}
            onPrevClick={() => nextTab(DIAGNOSE_MODAL_CONNECTION_STEP)}
          />
        )}
        {activeTabType === DIAGNOSE_MODAL_RESULT_STEP && <DiagnoseResultStep item={item} />}
      </div>
    </div>
  );
};

Diagnose.propTypes = {
  toPrevStep: PropTypes.func
};
Diagnose.defaultProps = {
  toPrevStep: () => {}
};

export default Diagnose;
