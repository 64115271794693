import React, { useState } from 'react';
import NotificationList from '../components/NotificationList';
import { ReactComponent as CommentsOnIcon } from '../assets/images/comments-on.svg';
import { ReactComponent as CommentsOffIcon } from '../assets/images/comments-off.svg';
import { ReactComponent as AlertsOnIcon } from '../assets/images/notifications-on.svg';
import { ReactComponent as AlertsOffIcon } from '../assets/images/notifications-off.svg';
import usePageContext from '../hooks/usePageContext';

const NotificationsBlock = ({ toggleSidebar }) => {
  const context = usePageContext();
  const [type, setType] = useState();
  const click = nType => {
    setType(nType);
    const sideBar = document.getElementById('sidebar-links');
    sideBar.classList.add('hide');
  };
  const close = () => {
    setType();
    const sideBar = document.getElementById('sidebar-links');
    sideBar.classList.remove('hide');
  };

  return (
    <div className={`notifications ${type && 'show'}`}>
      <button type="button" id="sidebar-links-hide" onClick={close} className="d-none" />
      <button
        onClick={() => click('Comments')}
        type="button"
        className={`reset-button notification-link ${type && 'd-none'}`}
      >
        {context.comments.find(x => x.status === 'unread') ? (
          <CommentsOnIcon className="d-inline-flex" />
        ) : (
          <CommentsOffIcon className="d-inline-flex" />
        )}
        <span>Comments</span>
      </button>
      <button
        onClick={() => click('Alerts')}
        type="button"
        className={`reset-button notification-link ${type && 'd-none'}`}
      >
        {context.alerts.find(x => x.status === 'unread') ? (
          <AlertsOnIcon className="d-inline-flex" />
        ) : (
          <AlertsOffIcon className="d-inline-flex" />
        )}
        <span>Alerts</span>
      </button>
      {type && <NotificationList title={type} close={close} />}
      {!type && <div className="line-divider" />}
    </div>
  );
};

export default NotificationsBlock;
