import connectionType from './connectionType';

const DiagnoseResultStep = ({ item }) => {
  return (
    <div className="info">
      <div className="info-block">
        <div className="w-100">
          <div className="info-value">
            <span className="title-value">IP Address:Port</span>
            <span className="value">{`${item.host}:${item.port}`}</span>
          </div>
        </div>
        <div className="info-block w-100">
          <div className="info-value site">
            <span className="title-value">Site:</span>
            <span className="value">{item.site_name || 'N/a'}</span>
          </div>
          <div className="info-block block-result">
            <div className="info-value connection">
              <span className="title-value">Status:</span>
              <span className="value">{item.commandStatus}</span>
            </div>
            <div className="info-value connection">
              <span className="title-value">Software version:</span>
              <span className="value">{item.command_response || 'N/a'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DiagnoseResultStep.propTypes = {
  item: connectionType.isRequired
};

export default DiagnoseResultStep;
