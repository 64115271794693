/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { useState } from 'react';
import Collapsible from 'react-collapsible';

const Tags = ({ id, tags, extraClass }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {tags.length > 0 && (
        <div className="tags-label">
          <span>Facility tags:</span>
          <span className="protean-link" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? 'Hide all' : 'Show all'}
          </span>
          <Collapsible open={isOpen}>
            <div className={`d-flex tags-wrapper inline ${extraClass}`}>
              <>
                <div className="tags">
                  {tags.map(tag => {
                    return (
                      <div className="label" key={`${tag.replace(/ /g, '')}-${id}`}>
                        {tag}
                      </div>
                    );
                  })}
                </div>
              </>
            </div>
          </Collapsible>
        </div>
      )}
    </>
  );
};

Tags.propTypes = {
  id: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  extraClass: PropTypes.string
};
Tags.defaultProps = {
  tags: [],
  extraClass: ''
};

export default Tags;
