import styled from 'styled-components';
import PropTypes from 'prop-types';

const FullPageSpinerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 99999;
  color: #7a86a9;
`;

const NewFullPageSpinerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.4;
  .spinner-border {
    opacity: 1;
    color: #7a86a9;
  }
`;

const RelativeSpinerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 999;
  color: #7a86a9;
`;

const BlockSpinerWrapper = styled.div`
  display: block;
  height: 100%;
  min-height: 150px
  width: 100%;
  position: relative;
  color: #7a86a9;
`;

export const ToCenterWraper = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ButtonSpinner = ({ styles }) => {
  return (
    <div className="spinner-border spinner-border-sm protean-loader" role="status" style={styles} />
  );
};
ButtonSpinner.propTypes = {
  styles: PropTypes.instanceOf(Object)
};

ButtonSpinner.defaultProps = {
  styles: {}
};

export const Spinner = ({ styles }) => {
  return (
    <div className="spinner-border protean-loader" role="status" style={styles}>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

Spinner.propTypes = {
  styles: PropTypes.instanceOf(Object)
};

Spinner.defaultProps = {
  styles: {}
};

export const SmallSpinner = () => {
  return (
    <div className="spinner-border spinner-border-sm protean-loader" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const ResultItemSpinner = () => {
  return (
    <div className="spinner-border spinner-border-result protean-loader" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const RelativeSpinner = () => {
  return (
    <RelativeSpinerWrapper>
      <Spinner />
    </RelativeSpinerWrapper>
  );
};

export const BlockSpinner = ({ extraClass }) => {
  return (
    <BlockSpinerWrapper className={extraClass}>
      <RelativeSpinner />
    </BlockSpinerWrapper>
  );
};

BlockSpinner.propTypes = {
  extraClass: PropTypes.string
};

BlockSpinner.defaultProps = {
  extraClass: ''
};

export const FullPageSpinner = () => {
  return (
    <FullPageSpinerWrapper>
      <Spinner />
    </FullPageSpinerWrapper>
  );
};

export const NewFullPageSpinner = () => {
  return (
    <NewFullPageSpinerWrapper>
      <Spinner />
    </NewFullPageSpinerWrapper>
  );
};
