import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { getUserData } from '../_actions';
import { checkPerms, getFirstEntry } from '../helpers/utils';

const ProtectedRoute = ({ component, path, visible, exact, name }) => {
  const user = getUserData();
  const param = window.location.pathname;
  if (visible === 'protected' && !user.logged_in) return <Redirect to={`/login?q=${param}`} />;
  if (visible === 'unauthorized' && user.logged_in)
    return <Redirect to={{ pathname: getFirstEntry() }} />;
  if (visible === 'unauthorized' && !user.logged_in) {
    return <Route exact={exact} path={path} component={component} />;
  }
  if (!checkPerms(name, user)) {
    return <Redirect to={{ pathname: '/404' }} />;
  }
  return <Route exact={exact} path={path} component={component} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  visible: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired
};

export default ProtectedRoute;
