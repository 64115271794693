/* eslint-disable */
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import usePageContext from '../hooks/usePageContext';
import axios from '../_actions/axiosInstance';
import NotificationPopup from './Notification';
import config from '../_config/config';
import history from '../helpers/history';

const NotificationItem = ({ item, markAsRead, view, close }) => {
  const message = useMemo(() => {
    if (!item.message.includes('You were mentioned in a new comment')) return item.message;
    const splitedMessage = item.message.split('You were mentioned in a new comment: ');
    if (!splitedMessage[1]) return item.message;
    const baseMessage = splitedMessage[1].substring(1).slice(0, -1);
    let str = '';
    baseMessage.split(' @{').map(part => {
      if (!part.includes('"}')) {
        str += `<span>${part}</span>`;
        return;
      }
      const mentionStr = part.split('"}')[0].replaceAll('@{', '');
      const textPart = part
        .split('"}')
        .slice(1)
        .filter(x => x && x !== ' ');
      const mentionObj = JSON.parse(`{${mentionStr}"}`);
      str += `<span class="mention-str" style="font-weight: bold"> ${mentionObj.display}</span>`;
      if (textPart.length) {
        str += `<span>${textPart}</span>`;
      }
    });
    return `You were mentioned in a new comment: "${str}"`;
  }, [item]);
  const viewWO = () => {
    close();
    if (document.getElementById('root').classList.value.includes('sidebar-mobile-main')) {
      document.getElementById('sidebar').classList.remove('sidebar-fullscreen');
    }
    document.getElementById('root').classList.toggle('sidebar-mobile-main');
    history.push(`/workorders/${item.wo_id}`);
  };
  return (
    <div className="notification-item">
      <button
        type="button"
        className={`reset-btn comment-indicator ${item.status}`}
        disabled={item.status === 'read'}
        onClick={() => markAsRead(item.id)}
        data-tip="as"
        data-for="mark-as-read"
      >
        <div className={`crc-indicator ${item.status}`} />
      </button>
      <div className="media-body">
        {ReactHtmlParser(message)}
        {item.wo_id && (
          <div className="extra-info">
            <div className="wo-label">
              Work order ID:
              <Link onClick={viewWO} className="wo-link" to={`/workorders/${item.wo_id}`}>
                {item.wo_name || 'WO'}
              </Link>
            </div>
            <div className="wo-site">{item.site_name}</div>
          </div>
        )}
        <div className="font-size-sm text-muted mt-1 d-flex justify-content-between">
          <button type="button" className="btn p-0 text-muted font-size-sm disabled time-ago">
            {moment(item.created_on, 'x').fromNow()}
          </button>
          {!!item?.link && (
            <button
              type="button"
              className="btn btn-link p-0 font-size-sm"
              onClick={() => view(item)}
            >
              View
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const NotificationList = ({ title, close }) => {
  const context = usePageContext();
  // eslint-disable-next-line react/destructuring-assignment
  const items = context[title.toLowerCase()];
  const [filter, setFilter] = useState(false);
  const { markAsRead } = context;
  // const hasUnRead = items.findIndex(x => x.status === 'unread') > -1;
  const filteredItems = filter ? items.filter(x => x.status === 'unread') : items;

  const readNotification = nid => {
    markAsRead(nid, title.toLowerCase());
  };

  const view = item => {
    close();
    if (document.getElementById('root').classList.value.includes('sidebar-mobile-main')) {
      document.getElementById('sidebar').classList.remove('sidebar-fullscreen');
    }
    document.getElementById('root').classList.toggle('sidebar-mobile-main');
    history.push(item.link.split('.com')[1]);
  };

  const submit = async () => {
    const type = title === 'Comments' ? 'comment' : 'notification';
    try {
      await axios.get(`${config.api.api_url}${config.api.notifications.mark_all}${type}`);
      await context.getItems();
    } catch (e) {
      console.log(e);
      toast.info(<NotificationPopup text="Server Error" title="Oops.." />);
    }
  };
  return (
    <div className={`notifications-block ${title}`}>
      <div className="buttons">
        <button type="button" onClick={close} className="reset-btn back-btn">
          <i className="icon-arrow-left8" />
          {title}
        </button>
        <button
          type="button"
          onClick={submit}
          className={`reset-btn mark-all-btn ${!filteredItems.length && 'invisible'}`}
        >
          Mark all as read
        </button>
      </div>
      <div className={`list-notification ${!filteredItems.length && 'no-data'}`}>
        {filteredItems.map(n => (
          <NotificationItem
            key={n.id}
            item={n}
            view={view}
            markAsRead={readNotification}
            close={close}
          />
        ))}
        {!filteredItems.length && <div className="no-data">No data</div>}
      </div>
      <button onClick={() => setFilter(!filter)} type="button" className="filter-items protean-btn">
        {filter ? 'View all' : 'View unread'}
      </button>
    </div>
  );
};

NotificationList.propTypes = {
  title: PropTypes.string,
  close: PropTypes.func
};

NotificationList.defaultProps = {
  title: '',
  close: () => {}
};

export default NotificationList;
