import PropTypes from 'prop-types';
import Select from 'react-select';
import SelectAsync from 'react-select/async';

export const LoadingIndicator = () => (
  <div className="select-loading-indicator">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

const AsyncSelect = ({
  trueAsync,
  placeholder,
  isLoading,
  options,
  isDisabled,
  components,
  setVal,
  value,
  isMulti,
  isClearable,
  getOptionValue,
  getOptionLabel,
  handleInputChange,
  loadOptions
}) => {
  const styles = {
    control: provided => ({ ...provided, boxShadow: 'none' })
  };

  const NewComponent = trueAsync ? SelectAsync : Select;

  return (
    <NewComponent
      styles={styles}
      placeholder={placeholder}
      isMulti={isMulti}
      options={!isLoading ? options : []}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      onChange={val => setVal(val)}
      value={value}
      isClearable={isClearable}
      isDisabled={isDisabled}
      loadingMessage={() => 'Loading..'}
      components={{ LoadingIndicator, ...components }}
      isLoading={isLoading}
      loadOptions={loadOptions}
      onInputChange={handleInputChange}
      menuPortalTarget={document.querySelector('body')}
    />
  );
};

AsyncSelect.propTypes = {
  trueAsync: PropTypes.bool,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  options: PropTypes.instanceOf(Array),
  isDisabled: PropTypes.bool,
  setVal: PropTypes.func,
  value: PropTypes.instanceOf(Object),
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  handleInputChange: PropTypes.func,
  loadOptions: PropTypes.func,
  components: PropTypes.instanceOf(Object)
};

AsyncSelect.defaultProps = {
  trueAsync: false,
  placeholder: 'Search',
  isLoading: false,
  options: [],
  isDisabled: false,
  setVal: () => {},
  value: null,
  components: {},
  isMulti: false,
  isClearable: false,
  getOptionValue: x => x.id,
  getOptionLabel: x => x.label,
  handleInputChange: () => {},
  loadOptions: () => {}
};

export default AsyncSelect;
