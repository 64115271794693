import PropTypes from 'prop-types';
import useATGStore from './store';

const InfoStep = ({ onCLose, onSubmit }) => {
  const [{ site }] = useATGStore();
  return (
    <div className="connection-setup ">
      <div className="info">
        <div className="title">
          You start the ATG connection diagnostics for the following site:
        </div>
        <div className="info-block">
          <div className="info-value">
            <span className="title-value">Address:</span>
            <span className="value">{site.host}</span>
          </div>
          <div className="info-value">
            <span className="title-value">Port:</span>
            <span className="value">{site.port}</span>
          </div>
          <div className="info-value">
            <span className="title-value">Site:</span>
            <span className="value">{site.atg_site}</span>
          </div>
        </div>
      </div>
      <div className="buttons">
        <button className="protean-btn" type="button" onClick={onCLose}>
          Cancel
        </button>
        <button className="protean-btn protean-btn-default" type="button" onClick={onSubmit}>
          Run diagnostics
        </button>
      </div>
    </div>
  );
};
InfoStep.propTypes = {
  onCLose: PropTypes.func,
  onSubmit: PropTypes.func
};
InfoStep.defaultProps = {
  onCLose: () => {},
  onSubmit: () => {}
};

export default InfoStep;
