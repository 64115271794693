import PropTypes from 'prop-types';
import ConnectionModal from './connectionModal';
import useATGStore from './store';

const DiagnosticButton = ({ site, openDiagnosticModal }) => {
  return (
    <div className="diagnostic-button">
      <button
        className="protean-btn ml-auto"
        type="button"
        disabled={!site}
        onClick={openDiagnosticModal}
      >
        Diagnose connection
      </button>
    </div>
  );
};

DiagnosticButton.propTypes = {
  site: PropTypes.instanceOf(Object),
  openDiagnosticModal: PropTypes.func.isRequired
};
DiagnosticButton.defaultProps = {
  site: null
};

const Diagnostic = () => {
  const [{ site, isDiagnosticModalOpen }, { openDiagnosticModal }] = useATGStore();

  return (
    <>
      <DiagnosticButton site={site} openDiagnosticModal={openDiagnosticModal} />
      {site && isDiagnosticModalOpen && <ConnectionModal />}
    </>
  );
};

export default Diagnostic;
