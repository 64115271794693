import PropTypes from 'prop-types';

export default PropTypes.shape({
  host: PropTypes.string.isRequired,
  port: PropTypes.number.isRequired,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  commandStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  id: PropTypes.string,
  command_response: PropTypes.string,
  site_name: PropTypes.string,
  success_ping: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  success_connection: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  success_command: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])])
});
