/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import AX from 'axios';
import { components } from 'react-select';
import axios from '../../_actions/axiosInstance';
import AsyncSelect from '../AsyncSelect';
import Tags from '../tags';

const NoOptionsMessage = props => {
  return (
    // eslint-disable-next-line
    <components.NoOptionsMessage {...props}>
      {/* eslint-disable-next-line */}
      {props.selectProps?.inputValue && !props?.options?.length
        ? 'No options'
        : 'Start typing to see options'}
    </components.NoOptionsMessage>
  );
};

const CustomOptionComponent = props => {
  const { data } = props;
  return (
    // eslint-disable-next-line
    <components.Option {...props}>
      <span>{data.atg_site}</span>
      <span className="site-atg-option-client">{`(${data.client_name})`}</span>
    </components.Option>
  );
};

CustomOptionComponent.propTypes = {
  data: PropTypes.instanceOf(Object)
};

CustomOptionComponent.defaultProps = {
  data: {}
};

const MenuList = props => {
  const { children } = props;
  return (
    <components.MenuList {...props} className="custom-select-scrollbar">
      {/* <div style={menuHeaderStyle}>Custom Menu List</div> */}
      {children}
    </components.MenuList>
  );
};

MenuList.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired
};

const DropdownIndicator = props => {
  const { isFocused } = props;
  return (
    <components.DropdownIndicator {...props} className={`indicator ${isFocused && 'rotate-180'}`} />
  );
};

DropdownIndicator.propTypes = {
  isFocused: PropTypes.bool.isRequired
};

const SelectSite = ({ site, selectSite, url, hideClear, hideTags, unlockApply, placeholder }) => {
  const sourceRef = useRef();

  const [currentSite, setCurrentSite] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  const clear = () => {
    setCurrentSite(null);
    selectSite(null);
  };

  const apply = () => {
    selectSite(currentSite);
    setIsTyping(false);
  };

  const loadOptions = async val => {
    if (sourceRef?.current?.cancel) {
      sourceRef.current.cancel('refresh');
    }
    sourceRef.current = AX.CancelToken.source();
    try {
      const res = await axios.get(`${url}?name=${encodeURIComponent(val)}`, {
        cancelToken: sourceRef.current.token
      });
      return res.data;
    } catch (err) {
      return [];
    }
  };

  return (
    <div className="facility-select">
      <div className="select-title mb-8">Select facility</div>
      <AsyncSelect
        trueAsync
        components={{
          NoOptionsMessage,
          Option: CustomOptionComponent,
          IndicatorSeparator: () => <></>,
          MenuList,
          DropdownIndicator
        }}
        loadOptions={loadOptions}
        placeholder={placeholder}
        setVal={setCurrentSite}
        value={currentSite}
        getOptionValue={option => option.site_id}
        getOptionLabel={option => option.atg_site}
        loadingMessage={() => 'Loading..'}
        handleInputChange={() => setIsTyping(true)}
      />
      <div className="buttons">
        <button
          className={`protean-btn ${hideClear && 'd-none'}`}
          type="button"
          onClick={clear}
          disabled={!currentSite}
        >
          Clear facility
        </button>
        <button
          className={`protean-btn protean-btn-default ${hideClear && 'noClear'}`}
          type="button"
          onClick={apply}
          disabled={unlockApply ? !currentSite : !isTyping || !currentSite}
        >
          Apply
        </button>
      </div>

      {site?.site_id && !hideTags && <Tags id={site.site_id} tags={site.tags} />}
    </div>
  );
};

SelectSite.propTypes = {
  site: PropTypes.instanceOf(Object),
  selectSite: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  hideClear: PropTypes.bool,
  hideTags: PropTypes.bool,
  unlockApply: PropTypes.bool,
  placeholder: PropTypes.string
};

SelectSite.defaultProps = {
  site: null,
  hideClear: false,
  hideTags: false,
  unlockApply: false,
  placeholder: 'Choose a facility'
};

export default SelectSite;
